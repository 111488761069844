import { ThemeContext, UserContext } from "~/app";
import { memo, useContext, useEffect, useRef, useState } from "react";

import Icon from "~/shared/components/icons";
import LanguageProvider from "../../../../shared/components/language-provider";
import { Menu } from "primereact/menu";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import PlatformRoles from "~/shared/enums/roles/PlatformRoles";
import { classNames } from "primereact/utils";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useMenuItemsContext } from "~/context/MenuItemsContext";
import { useNavigate } from "react-router-dom";

const DatabaseCard = ({ model }) => {
  const { currentTheme } = useContext(ThemeContext);
  const { idDataSource, name, databaseQtdRows, type } = model;
  const ref = useRef(null);
  const navigate = useNavigate();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);
  const userRolesFromStorage = propertyFrommStorage(
    "authentication",
    "userClienteRoles"
  );
  const [isPlatformAdministrator, setIsPlatformAdministrator] = useState(false);

  const dcpAxiosService = useDcpAxiosService();

  const { loadMenuItems } = useMenuItemsContext();

  const deleteDatabase = async () => {
    setSubmitting(true);
    try {
      const { status } = await dcpAxiosService.delete(
        `${settings.Urls.Rest.Datalake}/delete`,
        "Platform",
        {
          params: {
            idDataSource: idDataSource,
            name: name,
            typeDataSource: type,
          },
        }
      );

      if (status === 200) {
        setDeleteConfirmationVisible(false);
      }
      loadMenuItems();
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };

  const menuItemTemplate = (
    options,
    color,
    fontWeight,
    viewName,
    onClick = () => {}
  ) => {
    return (
      <div
        className={classNames(options.className)}
        style={{ color, fontWeight: fontWeight, fontSize: "14px" }}
        onClick={onClick}
      >
        {LanguageProvider({ id: viewName })}
      </div>
    );
  };

  const menuItems = [
    ...(isPlatformAdministrator
      ? [
          {
            template: (item, options) =>
              menuItemTemplate(
                options,
                currentTheme.texPrimary,
                "400",
                "gen.edit.button",
                () => navigate(`/database/edit/${idDataSource}`)
              ),
          },
        ]
      : []),
    {
      template: (item, options) =>
        menuItemTemplate(options, "#F9485B", "400", "gen.exclude.button", () =>
          setDeleteConfirmationVisible(true)
        ),
    },
  ];

  useEffect(() => {
    const platformRole = PlatformRoles.ADMINISTRATOR.split(".")[1];
    setIsPlatformAdministrator(
      userRolesFromStorage?.some(
        (role) =>
          role.roleName === platformRole &&
          role.applicationName === "product.platform"
      )
    );
  }, [userRolesFromStorage]);

  return (
    <div
      onClick={() => {
        if (!deleteConfirmationVisible) {
          navigate(`/database/list/${idDataSource}`);
        }
      }}
    >
      <div className="card database-card fade-in">
        <div className="database-card-header">
          <div className="database-icon-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14 20C14 21.1046 13.1046 22 12 22C10.8954 22 10 21.1046 10 20M14 20C14 18.8954 13.1046 18 12 18M14 20H21M10 20C10 18.8954 10.8954 18 12 18M10 20H3M12 18V14M21 5C21 6.65685 16.9706 8 12 8C7.02944 8 3 6.65685 3 5M21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5M21 5V11C21 12.66 17 14 12 14M3 5V11C3 12.66 7 14 12 14"
                stroke={"#95A1F7"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="popup_menu_right settings-button"
            onClick={(e) => {
              ref.current.toggle(e);
              e.stopPropagation();
            }}
          >
            <Icon
              icon={"dots-vertical"}
              size={30}
              color={"#8189A3"}
              className={"settings-field-button"}
            />
            <Menu
              popup
              model={menuItems}
              ref={ref}
              id="settings-card-pop-up"
            ></Menu>
          </div>
        </div>
        <div className="database-card-content">
          <span className="database-name">{name}</span>
          <span className="database-entries">
            {databaseQtdRows + " "}
            {LanguageProvider({
              id: "dashboard.database.registers",
            })}
          </span>
        </div>
      </div>

      {/* Delete field modal */}
      <ModalConfirmation
        isOpen={deleteConfirmationVisible}
        modalTitle={<LanguageProvider id="database.delete.title" />}
        bodyMessage={<LanguageProvider id="database.delete.message" />}
        isDelete={true}
        onConfirm={deleteDatabase}
        onCancel={() => {
          setDeleteConfirmationVisible(false);
        }}
        loading={submitting}
      />
    </div>
  );
};

export default memo(DatabaseCard);
