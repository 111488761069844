import "./scss/b2b-orders-monitor-list.scss";
import settings from "~/services/settings.json";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "~/app";
import { ListB2bOrderProcess } from "~/services/api/B2b/boticario/monitor-process";
import { Button } from "~/shared/components/dcp-button";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { Calendar } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip";
import OrderMonitorStatus from "~/shared/enums/B2b/OrderMonitorStatus";
import { Nullable } from "primereact/ts-helpers";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode } from "primereact/api";
import ModalOrderMonitorDetails from "./components/modal-order-monitor-details";

export function B2BOrdersMonitorList() {
  const { currentTheme } = useContext(ThemeContext);
  const { currentLanguage } = useContext(ThemeContext);

  const downloadOptionsOpRef = useRef(null);
  const [globalFilters, setGlobalFilters] = useState("");
  const [dateRange, setDateRange] = useState<Nullable<(Date | null)[]>>([
    new Date(new Date().setDate(new Date().getDate() - 60)),
    new Date(),
  ]);
  const [orderId, setOrderId] = useState(0);
  const [orderProcessData, setOrderProcessData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetailsDialogVisible, setOrderDetailsDialogVisible] =
    useState(false);
  const statusDropdownOptions = [
    {
      label: LanguageProviderWithoutContext({
        id: "dcp.b2b.monitor.order.status.pending-packing",
        currentLanguage,
      }),
      value: OrderMonitorStatus.pendingPacking,
    },
    {
      label: LanguageProviderWithoutContext({
        id: "dcp.b2b.monitor.order.status.carrier-definition",
        currentLanguage,
      }),
      value: OrderMonitorStatus.carrierDefinition,
    },
    {
      label: LanguageProviderWithoutContext({
        id: "dcp.b2b.monitor.order.status.carrier-error",
        currentLanguage,
      }),
      value: OrderMonitorStatus.carrierError,
    },
    {
      label: LanguageProviderWithoutContext({
        id: "dcp.b2b.monitor.order.status.pending-integration-carrier",
        currentLanguage,
      }),
      value: OrderMonitorStatus.pendingIntegrationCarrier,
    },
    {
      label: LanguageProviderWithoutContext({
        id: "dcp.b2b.monitor.order.status.error-carrier-integration",
        currentLanguage,
      }),
      value: OrderMonitorStatus.errorCarrierIntegration,
    },
    {
      label: LanguageProviderWithoutContext({
        id: "dcp.b2b.monitor.order.status.pending-report",
        currentLanguage,
      }),
      value: OrderMonitorStatus.pendingReport,
    },
    {
      label: LanguageProviderWithoutContext({
        id: "dcp.b2b.monitor.order.status.error-report",
        currentLanguage,
      }),
      value: OrderMonitorStatus.errorReport,
    },
    {
      label: LanguageProviderWithoutContext({
        id: "dcp.b2b.monitor.order.status.pending-print",
        currentLanguage,
      }),
      value: OrderMonitorStatus.pendingPrint,
    },
    {
      label: LanguageProviderWithoutContext({
        id: "dcp.b2b.monitor.order.status.pendent-shipping",
        currentLanguage,
      }),
      value: OrderMonitorStatus.pendingShipping,
    },
    {
      label: LanguageProviderWithoutContext({
        id: "dcp.b2b.monitor.order.status.finished",
        currentLanguage,
      }),
      value: OrderMonitorStatus.finished,
    },
  ];
  const [selectedStatus, setSelectedStatus] = useState([
    OrderMonitorStatus.pendingPacking,
    OrderMonitorStatus.carrierDefinition,
    OrderMonitorStatus.carrierError,
    OrderMonitorStatus.pendingIntegrationCarrier,
    OrderMonitorStatus.errorCarrierIntegration,
    OrderMonitorStatus.pendingReport,
    OrderMonitorStatus.errorReport,
    OrderMonitorStatus.pendingPrint,
    OrderMonitorStatus.pendingShipping,
    OrderMonitorStatus.finished,
  ]);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    first: 0,
    page: 0,
    rows: settings.RowsPerPageOptions.Default,
    sortField: null,
    sortOrder: 1,
    globalFilter: "",
    monitorOrdersSelectedStatus: selectedStatus,
    startDateUpdateRange: null,
    endDateUpdateRange: null,
    filters: {
      status: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.CUSTOM,
          },
        ],
      },
    },
  });

  const fetchOrderProcess = async (filter) => {
    setIsLoading(true);
    try {
      const { data, status } = await ListB2bOrderProcess(
        formatFilterRequest(filter)
      );
      if (status) {
        setTotalItems(data?.totalRecords);
        setOrderProcessData(data?.orderProcesses);
      }
    } catch (error) {
      console.error("Erro ao buscar os dados");
    } finally {
      setIsLoading(false);
    }
  };

  const CloseOrderProcessModal = () => {
    setOrderDetailsDialogVisible(false);
  };

  const onMonitorStatusFilter = (monitorStatus = selectedStatus) => {
    setSelectedStatus(monitorStatus);
  };

  function formatFilterRequest(filter) {
    try {
      let startUpdateDate = dateRange[0];
      let endUpdateDate = dateRange[1];

      let payload = {
        ...filter,
        monitorOrdersSelectedStatus: filter.monitorOrdersSelectedStatus,
        startDateUpdateRange: startUpdateDate,
        endDateUpdateRange: endUpdateDate,
      };
      payload.sortOrder = payload.sortOrder ?? 0;

      let _filters = [];

      for (const columnName in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, columnName)) {
          const _filter = payload.filters[columnName];

          if (_filter.constraints[0].value)
            _filters.push({
              columnName,
              value: _filter.constraints[0].value,
              matchMode: _filter.constraints[0].matchMode,
            });
        }
      }

      payload.filters = _filters;
      return {
        ...payload,
        monitorOrdersSelectedStatus: payload.monitorOrdersSelectedStatus,
        startDateUpdateRange: payload.startDateUpdateRange,
        endDateUpdateRange: payload.endDateUpdateRange,
      };
    } catch (error) {
      console.error(error);
    }
  }

  const onFilter = (e) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...e,
      monitorOrdersSelectedStatus: selectedStatus,
    }));
    let _event = { ...e, first: 0 };

    for (const filter in _event.filters) {
      if (Object.hasOwnProperty.call(_event.filters, filter)) {
        let element = _event.filters[filter];
        if (Object.hasOwnProperty.call(element, "value")) {
          delete _event.filters[filter].value;
          delete _event.filters[filter].matchMode;
          _event.filters[filter].constraints[0].value = null;
        }
      }
    }

    let filtersWithMonitorStatus = null;
    filtersWithMonitorStatus = {
      ...filter,
      monitorOrdersSelectedStatus: selectedStatus,
    };
    setFilter(filtersWithMonitorStatus);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter({
      ...filter,
      globalFilter: value,
    });

    setGlobalFilters(value);
  };

  function renderStatusColumn(status) {
    switch (status) {
      case OrderMonitorStatus.finished:
        return (
          <span className="processing-status-finished">
            <LanguageProvider id={"dcp.b2b.monitor.order.status.finished"} />
          </span>
        );
      case OrderMonitorStatus.pendingShipping:
        return (
          <span className="processing-status-pending">
            <LanguageProvider
              id={"dcp.b2b.monitor.order.status.pendent-shipping"}
            />
          </span>
        );
      case OrderMonitorStatus.carrierDefinition:
        return (
          <span className="processing-status-carrier-definition">
            <LanguageProvider id="dcp.b2b.monitor.order.status.carrier-definition" />
          </span>
        );
      case OrderMonitorStatus.pendingIntegrationCarrier:
        return (
          <span className="processing-status-loaded">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-integration-carrier" />
          </span>
        );
      case OrderMonitorStatus.pendingPacking:
        return (
          <span className="processing-status-packing">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-packing" />
          </span>
        );
      case OrderMonitorStatus.carrierError:
        return (
          <span className="processing-status-carrier-error">
            <LanguageProvider id="dcp.b2b.monitor.order.status.carrier-error" />
          </span>
        );
      case OrderMonitorStatus.errorCarrierIntegration:
        return (
          <span className="processing-status-carrier-integration">
            <LanguageProvider id="dcp.b2b.monitor.order.status.error-carrier-integration" />
          </span>
        );
      case OrderMonitorStatus.pendingReport:
        return (
          <span className="processing-status-report-pending">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-report" />
          </span>
        );
      case OrderMonitorStatus.errorReport:
        return (
          <span className="processing-status-error-report">
            <LanguageProvider id="dcp.b2b.monitor.order.status.error-report" />
          </span>
        );
      case OrderMonitorStatus.pendingPrint:
        return (
          <span className="processing-status-print-pending">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-print" />
          </span>
        );
      default:
        return <span>{status}</span>;
    }
  }

  useEffect(() => {
    let startDate = new Date();
    let endDate = new Date();

    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange ? dateRange[0] : null;
      endDate = dateRange ? dateRange[1] : null;
    }

    filter.monitorOrdersSelectedStatus = selectedStatus;
    if (dateRange.length <= 0) {
      startDate.setMonth(startDate.getMonth() - 1);
      filter.startDateUpdateRange = startDate;
      filter.endDateUpdateRange = endDate;
    }
    if (selectedStatus.length <= 0) {
      filter.monitorOrdersSelectedStatus = statusDropdownOptions.map(
        (status) => status.value
      );
    }
    fetchOrderProcess(filter);
  }, [filter, dateRange, selectedStatus]);

  return (
    <div className="orders-monitor-dashboard">
      <div className="orders-monitor-container">
        <div className="orders-monitor-content">
          <div className="content-header">
            <div className="total-items-wrapper">
              <span className="header-message">
                <div className="page-identifier-wrapper">
                  <span className="page-name">
                    <LanguageProvider id="dcp.b2b.monitor.order.list.title" />
                  </span>
                </div>
                <div className="total-table-itens">
                  <span className="total-register-message">
                    {`${totalItems} ${LanguageProvider({
                      id: "gen.registers",
                    })}`}
                  </span>
                </div>
              </span>
            </div>
            <div className="options-wrapper">
              <div className="filter-buttons-wrapper">
                <span className="search-input">
                  <Icon icon={"search-md"} size={20} color={"#667085"} />
                  <InputText
                    className="p-inputtext-md"
                    placeholder={LanguageProviderWithoutContext({
                      id: "search.field.placeholder",
                      currentLanguage,
                    })}
                    value={globalFilters}
                    onChange={(e) => setGlobalFilters(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Tab") {
                        onGlobalFilterChange(e);
                      }
                    }}
                  />
                </span>
                <div className="field-calendar">
                  <Icon icon="calendar" />
                  <Calendar
                    className="field-calendar-input"
                    selectionMode="range"
                    dateFormat="dd/mm/yy"
                    value={dateRange}
                    onChange={(e) => setDateRange(e.value)}
                    readOnlyInput
                  />
                </div>
                <div className="field-dropdown">
                  <MultiSelect
                    className="field-dropdown-input"
                    options={statusDropdownOptions}
                    value={selectedStatus}
                    optionLabel="label"
                    optionValue="value"
                    display="chip"
                    maxSelectedLabels={4}
                    onChange={(e) => {
                      const monitorStatusSelected: OrderMonitorStatus[] =
                        [] as OrderMonitorStatus[];
                      monitorStatusSelected.push(...e.value);
                      onMonitorStatusFilter(monitorStatusSelected);
                    }}
                    placeholder="Status"
                  />
                </div>
              </div>
              <Button
                className="p-button p-button-secondary btn-download-database"
                onClick={(e) => downloadOptionsOpRef?.current.toggle(e)}
                icon={
                  <Icon size={20} icon="download-01" color={"var(--primary)"} />
                }
              />
              <Tooltip target=".btn-download-database" position="top">
                <span>
                  <LanguageProvider id="gen.download" />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="content-body">
            {/*@ts-ignore*/}
            <DataTable
              lazy
              loading={isLoading}
              loadingIcon={<LoadingIndicator />}
              value={orderProcessData}
              paginator
              rows={filter.rows}
              first={filter.first}
              onPage={setFilter}
              onSort={setFilter}
              onFilter={(e) => onFilter(e)}
              totalRecords={totalItems}
              sortField={filter.sortField}
              sortOrder={filter.sortOrder}
              filters={filter.filters}
              rowsPerPageOptions={settings.RowsPerPageOptions.Default}
            >
              <Column
                field="orderId"
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.order"}
                  />
                }
              />
              <Column
                filter
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.status"}
                  />
                }
                sortable
                body={(rowData) => (
                  <div className="order-status">
                    {renderStatusColumn(rowData.status)}
                  </div>
                )}
              />
              <Column
                field="volume"
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.volume"}
                  />
                }
              />
              <Column
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.cost"}
                  />
                }
                body={(rowData) => (
                  <span>
                    {rowData.totalCost ? "$ " + rowData.totalCost : ""}
                  </span>
                )}
              />
              <Column
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.city"}
                  />
                }
                field="city"
              />
              <Column
                header={
                  <LanguageProvider
                    id={"dcp.b2b.monitor.order.list.column.update.date"}
                  />
                }
                body={(rowData) => (
                  <span>
                    {rowData.lastUpdated
                      ? new Date(rowData.lastUpdated).toLocaleString()
                      : ""}
                  </span>
                )}
              />
              <Column
                body={(rowData) => (
                  <div className="actions">
                    <Icon
                      icon={"eye"}
                      size={20}
                      color={currentTheme.tableActionIcon}
                      onClick={() => {
                        setOrderId(rowData?.id);
                        setOrderDetailsDialogVisible(true);
                      }}
                      className="action-icon"
                    />
                  </div>
                )}
              />
            </DataTable>
          </div>
        </div>
      </div>
      <ModalOrderMonitorDetails
        orderId={orderId}
        isOpen={orderDetailsDialogVisible}
        onClose={CloseOrderProcessModal}
      />
    </div>
  );
}

export default B2BOrdersMonitorList;
