import "../scss/area-history-location-dialog.scss";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ThemeContext } from "~/app";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { InputText } from "primereact/inputtext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";
import { DcpInlineZoomImage } from "~/shared/components/dcp-zoom-image-inline";
import { Button } from "~/shared/components/dcp-button";

export function AreaHistoryLocationDialog({ isOpen, onClose, locations }) {
  const { currentTheme } = useContext(ThemeContext);
  const { currentLanguage } = useLanguageContext();
  const axiosService = useDcpAxiosService();
  const [isLoading, setIsLoading] = useState(true);
  const [isSkuTableLoading, setIsSkuTableLoading] = useState(false);
  const [selectedCurrentLocation, setSelectedCurrentLocation] = useState(
    locations[0]
  );
  const [selectedLocation, setSelectedLocation] = useState(
    locations[0]?.locationId
  );
  const [locationsHistoryOptions, setLocationsHistoryOptions] = useState([]);
  const [
    historyLocationSelectedPalletLabel,
    setHistoryLocationSelectedPalletLabel,
  ] = useState(null);
  const [inventoryItemCountResultItens, setInventoryItemCountResultItens] =
    useState([]);
  const [
    clientHistoryAdditionalInfoProperties,
    setClientHistoryAdditionalInfoProperties,
  ] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [locationImageHistoryLoading, setLocationImageHistoryLoading] =
    useState(false);
  const [imagesLocationURL, setImagesLocationURL] = useState("");
  const [images, setImages] = useState([]);
  const zoomRef = useRef<any>(null);

  const selectImage = (index) => {
    setSelectedImage(index);
  };

  const getInventoryHistoryLocationImage = async () => {
    try {
      setLocationImageHistoryLoading(true);
      const { data, status } = await axiosService.get(
        `${settings.Urls.Rest.Inventory}/history-location-images`,
        "Inventory",
        {
          params: {
            idInventoryLocation: selectedLocation,
          },
        }
      );
      if (status === 200 && data.data) {
        const imagesResponse = data?.data[0]?.images ?? [];
        if (data.data.length > 0) {
          setImagesLocationURL(imagesResponse[0].url);
          setImages(imagesResponse.map((image) => image.url));
        } else {
          setImagesLocationURL("");
          setImages([]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLocationImageHistoryLoading(false);
    }
  };

  const getInventoryItemCount = async () => {
    try {
      setIsSkuTableLoading(true);
      const response = await axiosService.get(
        settings.Urls.Rest.Inventory + "/list-read-location-data",
        "Inventory",
        { params: { idLocation: selectedLocation } }
      );
      if (response.status === 200 && response.data.data) {
        setInventoryItemCountResultItens(response.data.data);
        const resultItensList = response.data.data.map((item) => item.id);
        await getUserAdditionalInfo(resultItensList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSkuTableLoading(false);
    }
  };

  const getUserAdditionalInfo = async (inventoryLocationUserResultId) => {
    if (
      inventoryLocationUserResultId &&
      inventoryLocationUserResultId.length > 0
    ) {
      try {
        const allAdditionalInfo = [];
        for (const id of inventoryLocationUserResultId) {
          const { data, status } = await axiosService.get(
            `${settings.Urls.Rest.Inventory}/get-history-location-additional-info`,
            "Inventory",
            {
              params: { idHistoryLocatioUserResult: id },
            }
          );
          if (status === 200 && data.data) {
            allAdditionalInfo.push(...data.data);
          }
        }
        setClientHistoryAdditionalInfoProperties(allAdditionalInfo);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const CloseModal = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (locations.length !== 0) {
      setIsLoading(true);
      setSelectedCurrentLocation(locations[0]);
      setSelectedLocation(locations[0]?.locationId);
      setHistoryLocationSelectedPalletLabel(locations[0]?.palletLabel);
      setIsLoading(false);
    }

    const locationsHistoryFormatted = locations.map((location) => ({
      label: location.locationName,
      value: location.locationId,
    }));

    setLocationsHistoryOptions(
      locationsHistoryFormatted.length > 0 &&
        locationsHistoryFormatted.sort((a, b) => a.label.localeCompare(b.label))
    );
    getInventoryHistoryLocationImage();
  }, [isOpen, locations]);

  useEffect(() => {
    getInventoryItemCount();
    getInventoryHistoryLocationImage();
  }, [selectedCurrentLocation, selectedLocation]);

  return (
    <>
      <Dialog
        visible={isOpen}
        onHide={CloseModal}
        className="dialog-area-history-details"
        maximized={false}
      >
        {!isLoading ? (
          <div className="barcode-read">
            <div className="barcode-read-content">
              <div className="barcode-details">
                <div className="return-option-wrapper">
                  <div className="icon-return">
                    <Icon
                      icon="arrow-narrow-left"
                      color="#4146FF"
                      size={20}
                      onClick={CloseModal}
                    />
                  </div>
                  <span className="return-text-title">
                    <LanguageProvider id="gen.area.history" />
                  </span>
                </div>
                <div className="barcode-form-wrapper">
                  <div className="dcp-form">
                    <div className="form-row">
                      <div className="input-container location-name">
                        <label className="identifier-location-label">
                          Confiabilidade da imagem
                        </label>
                        <span className="content-location">
                          {selectedCurrentLocation?.reliability ===
                            settings.inventoryReliability?.reliable && (
                            <span className="reliability-reliable">
                              <LanguageProvider id="gen.reliable" />
                            </span>
                          )}
                          {selectedCurrentLocation?.reliability ===
                            settings.inventoryReliability?.lessReliable && (
                            <span className="reliability-lessReliable">
                              <LanguageProvider id="gen.less.reliable" />
                            </span>
                          )}
                          {selectedCurrentLocation?.reliability ===
                            settings.inventoryReliability?.unreliable && (
                            <span className="reliability-unreliable">
                              <LanguageProvider id="gen.unreliable" />
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="input-container street-name">
                        <label className="identifier-location-label">
                          <LanguageProvider id="gen.street" />
                        </label>
                        <span className="content-location">
                          {selectedCurrentLocation?.locationName.split("-")[0]}
                        </span>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="input-container location-name">
                        <label className="identifier-location-label">
                          <LanguageProvider id="gen.location" />
                        </label>
                        <span className="content-location">
                          {selectedCurrentLocation?.locationName}
                        </span>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="input-container scan-date">
                        <label className="identifier-location-label">
                          <LanguageProvider id="gen.scanned" />
                        </label>
                        <span className="content-location">
                          {selectedCurrentLocation?.lastUpdate ? (
                            `${new Date(
                              selectedCurrentLocation.lastUpdate
                            ).toLocaleDateString("pt-BR", {
                              day: "2-digit",
                              month: "long",
                            })} ${new Date(
                              selectedCurrentLocation.lastUpdate
                            ).toLocaleTimeString("pt-BR")}`
                          ) : (
                            <span>
                              <LanguageProvider
                                id={"location.history.no.record"}
                              />
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="input-container location-name">
                        <label className="identifier-location-label">
                          Inventário
                        </label>
                        <span className="content-location inventory-link">
                          {selectedCurrentLocation?.inventoryCode ? (
                            <span
                              onClick={() =>
                                window.open(
                                  `/inventory-dev/inventory/${selectedCurrentLocation.inventoryId}`,
                                  "_blank"
                                )
                              }
                            >
                              {selectedCurrentLocation.inventoryCode}
                            </span>
                          ) : (
                            <span>
                              <LanguageProvider
                                id={"location.history.no.record"}
                              />
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="input-container location-name">
                        <span className="identifier-location-label">
                          Leituras
                        </span>
                        <div className="content-body">
                          <div className="dcp-table">
                            <div className="itens-table">
                              <DataTable
                                value={inventoryItemCountResultItens}
                                emptyMessage={LanguageProviderWithoutContext({
                                  id: "location.history.no.record",
                                  currentLanguage,
                                })}
                                loading={isSkuTableLoading}
                              >
                                <Column
                                  body={(rowData) => {
                                    const statusIcon = [
                                      <Icon
                                        icon="check-circle"
                                        color="var(--green-500)"
                                        size={20}
                                      />,
                                      <Icon
                                        icon="x-circle"
                                        color="var(--red-500)"
                                        size={20}
                                      />,
                                      <Icon
                                        icon="edit-05"
                                        color="var(--blue-600)"
                                        size={20}
                                      />,
                                    ];

                                    if (rowData.modifiedAt != null)
                                      return statusIcon[2];
                                    if (rowData.systemAmount !== rowData.result)
                                      return statusIcon[1];
                                    return statusIcon[0];
                                  }}
                                />
                                <Column
                                  field="sku"
                                  header="SKU"
                                  body={(rowData) => {
                                    return rowData.sku === ""
                                      ? LanguageProvider({ id: "gen.no.sku" })
                                      : rowData.sku;
                                  }}
                                />
                                <Column
                                  field="systemAmount"
                                  header={
                                    <LanguageProvider id="inventory.system.qtd" />
                                  }
                                />
                                <Column field="result" header="Qtd" />
                              </DataTable>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {historyLocationSelectedPalletLabel && (
                      <div className="form-row">
                        <div className="input-container location-label">
                          <label className="identifier-location-label">
                            <LanguageProvider id={"gen.audit.field.label"} />
                          </label>
                          <InputText
                            disabled
                            className="content-location-label"
                            value={historyLocationSelectedPalletLabel}
                          />
                        </div>
                      </div>
                    )}
                    {clientHistoryAdditionalInfoProperties &&
                      clientHistoryAdditionalInfoProperties.length > 0 &&
                      clientHistoryAdditionalInfoProperties.map((item) => (
                        <div className="form-row">
                          <div className="input-container location-label">
                            <label className="identifier-location-label">
                              {item.propertyName}
                            </label>
                            <InputText
                              disabled
                              className="content-location-label"
                              value={item.defaultValue}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="barcode-image">
                <div className="location-select-header">
                  <div className="location-image-header-buttons">
                    <Button
                      className="p-button-secondary"
                      onClick={() => zoomRef.current?.zoomIn()}
                    >
                      <Icon
                        icon={"zoom-in"}
                        color={currentTheme.tableIconColor}
                        size={20}
                      />
                    </Button>
                    <Button
                      className="p-button-secondary"
                      onClick={() => zoomRef.current?.zoomOut()}
                    >
                      <Icon
                        icon={"zoom-out"}
                        color={currentTheme.tableIconColor}
                        size={20}
                      />
                    </Button>
                    <Button
                      className="p-button-secondary"
                      onClick={() => zoomRef.current?.resetTransform()}
                    >
                      <Icon
                        icon={"expand-06"}
                        color={currentTheme.tableIconColor}
                        size={20}
                      />
                    </Button>
                  </div>
                  <div className="location-select-dropdown">
                    <Icon
                      className={`left-icon-arrow${
                        locations.findIndex(
                          (location) => location.locationId === selectedLocation
                        ) === 0
                          ? "-disabled"
                          : ""
                      }`}
                      color={currentTheme.tableIconColor}
                      icon={"chevron-left"}
                      size={20}
                      onClick={() => {
                        setImagesLocationURL("");
                        setImages([]);
                        const currentIndex = locations.findIndex(
                          (location) => location.locationId === selectedLocation
                        );
                        if (currentIndex > 0) {
                          const previousLocation = locations[currentIndex - 1];
                          const inventoryCountLocationSelected =
                            locations?.find(
                              (location) =>
                                location?.location?.id ===
                                previousLocation?.value
                            );
                          setSelectedLocation(previousLocation.locationId);
                          setSelectedCurrentLocation(previousLocation);
                          setHistoryLocationSelectedPalletLabel(
                            inventoryCountLocationSelected.palletLabel
                          );
                        }
                      }}
                    />
                    <Dropdown
                      className="location-name-dropdown"
                      filter
                      options={locations && locationsHistoryOptions}
                      value={selectedLocation && selectedLocation}
                      onChange={(e) => {
                        setImagesLocationURL("");
                        setImages([]);
                        setSelectedLocation(e.value);
                        const selectedLocationObj = locations.find(
                          (location) => location.locationId === e.value
                        );
                        setSelectedCurrentLocation(selectedLocationObj);
                        setHistoryLocationSelectedPalletLabel(
                          selectedLocationObj.palletLabel
                        );
                      }}
                      loading={isLoading}
                    />
                    <Icon
                      className={`right-icon-arrow${
                        locations.findIndex(
                          (location) => location.locationId === selectedLocation
                        ) ===
                        locations.length - 1
                          ? "-disabled"
                          : ""
                      }`}
                      color={currentTheme.tableIconColor}
                      icon={"chevron-right"}
                      size={20}
                      onClick={() => {
                        setImagesLocationURL("");
                        setImages([]);
                        const currentIndex = locations.findIndex(
                          (location) => location.locationId === selectedLocation
                        );
                        if (currentIndex < locations.length - 1) {
                          const nextLocation = locations[currentIndex + 1];
                          setSelectedLocation(nextLocation.locationId);
                          setSelectedCurrentLocation(nextLocation);
                          const inventoryCountLocationSelected =
                            locations?.find(
                              (location) =>
                                location?.location?.id === nextLocation?.value
                            );
                          setHistoryLocationSelectedPalletLabel(
                            inventoryCountLocationSelected.palletLabel
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                {!locationImageHistoryLoading ? (
                  imagesLocationURL && imagesLocationURL !== "" ? (
                    <DcpInlineZoomImage
                      ref={zoomRef}
                      src={imagesLocationURL}
                      alt="Location Image"
                      className="location-image"
                      imageClassName="location-img"
                    />
                  ) : (
                    <div className="no-image">
                      <LanguageProvider id="gen.no.image" />
                    </div>
                  )
                ) : (
                  <LoadingIndicator />
                )}
                <div className="paginator">
                  <span className="paginator-content">
                    {selectedCurrentLocation?.inventoryCode}{" "}
                    {selectedCurrentLocation?.lastUpdate ? (
                      `${new Date(selectedCurrentLocation.lastUpdate)
                        .toLocaleDateString("pt-BR")
                        .replace(/\D/g, "/")} ${new Date(
                        selectedCurrentLocation.lastUpdate
                      )
                        .toLocaleTimeString("pt-BR")
                        .replace(/\D/g, ":")}`
                    ) : (
                      <span>
                        <LanguageProvider id={"location.history.no.record"} />
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="barcode-thumbnail-session">
                <label className="barcode-image-thumbnail-title">
                  <LanguageProvider id="gen.historic" />
                </label>
                <div className="thumbnail-gallery">
                  {images.map((imageUrl, index) => (
                    <img
                      key={index}
                      src={imageUrl}
                      srcSet={`${imageUrl}?w=150 1x, ${imageUrl}?w=300 2x`}
                      alt={`Thumbnail ${index + 1}`}
                      className={
                        selectedImage === index
                          ? "thumbnail selected"
                          : "thumbnail"
                      }
                      loading="lazy"
                      style={{ imageRendering: "auto" }}
                      onClick={() => {
                        setImagesLocationURL(imageUrl);
                        selectImage(index);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <LoadingIndicator />
        )}
      </Dialog>
    </>
  );
}
