import "./image-upload.scss";
import { Dialog } from "primereact/dialog";
import React, { useContext, useEffect, useState } from "react";
import LanguageProvider from "~/shared/components/language-provider";
import { Dropdown } from "primereact/dropdown";
import { FileUploadBox } from "~/shared/components/file-upload-box.tsx";
import { InventoryContext } from "~/pages/inventory/inventory/inventory-details/inventory-details.tsx";
import {
  listGroupNames,
  listLocationNames,
  UploadLocationImage,
} from "~/services/api";
import { Button } from "~/shared/components/dcp-button.tsx";
import Icon from "~/shared/components/icons";
import { useToastContext } from "~/context/ToastContext";
import { Area } from "~/shared/interfaces";
import { AreaItemNameModel } from "~/shared/interfaces/area-group.ts";
import { ProgressBar } from "primereact/progressbar";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export interface ImageUploadProps {
  visible: boolean;
  onClose: () => void;
}

export function ImageUpload(props: ImageUploadProps) {
  // Dropdown options
  const { currentLanguage } = useLanguageContext();
  const {
    refreshUnknownImageCounterBadge,
    setRefreshUnknownImageCounterBadge,
  } = useContext(InventoryContext);
  const [groupOptions, setGroupOptions] = useState<AreaItemNameModel[]>();
  const [locationOptions, setLocationOptions] = useState<AreaItemNameModel[]>();

  // Loading indicators
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Data
  // @ts-ignore
  const [selectedFiles, setSelectedFiles] = useState<FileList>([]);
  const [selectedArea, setSelectedArea] = useState<Area>();
  const [selectedAreaGroup, setSelectedAreaGroup] =
    useState<AreaItemNameModel>();
  const [selectedLocation, setSelectedLocation] = useState<AreaItemNameModel>();
  const [progressValue, setProgressValue] = useState(0);
  const [indexCurrentImageUploading, setIndexCurrentImageUploading] =
    useState(0);
  var progressBarValueTemplate = null;

  // Contexts
  const { inventory } = useContext(InventoryContext);
  const { showToast } = useToastContext();

  const [isMultipleSelected, setIsMultipleSelected] = useState(false);

  function onClose() {
    setSelectedFiles(null);
    props.onClose();
  }

  async function uploadImage() {
    try {
      const totalImages = selectedFiles.length;
      progressBarValueTemplate = () => {
        return (
          <React.Fragment>
            `${indexCurrentImageUploading}/<b>${totalImages}`</b>
          </React.Fragment>
        );
      };
      for (const [index, file] of [...selectedFiles].entries()) {
        const form = new FormData();
        form.append("image", file);
        form.append("inventoryId", inventory.id.toString());

        if (selectedFiles.length > 1) {
          form.append("type", "multiple");
          form.append("areaId", "");
          form.append("locationId", "");
          form.append("locationType", "");
        } else {
          form.append("type", "single");
          form.append("areaId", selectedArea?.id.toString());
          form.append("locationId", selectedLocation?.id.toString());
          form.append(
            "locationType",
            selectedLocation?.locationType.toString()
          );
        }
        setSubmitting(true);
        await UploadLocationImage(form);

        setIndexCurrentImageUploading(index + 1);
        const progressPercentage = ((index + 1) / totalImages) * 100;
        setProgressValue(progressPercentage);
      }
      setSubmitting(false);
      showToast({
        severity: "success",
        message: LanguageProviderWithoutContext({
          id: "inventory.image.upload.success",
          currentLanguage,
        }),
      });

      if (!refreshUnknownImageCounterBadge) {
        setRefreshUnknownImageCounterBadge(true);
      }
      onClose();
    } catch (e) {
      console.error(e);
      showToast({
        severity: "error",
        message: LanguageProviderWithoutContext({
          id: "unhandled.error",
          currentLanguage,
        }),
      });
    }

    setSelectedFiles(null);
  }

  // Load area groups
  useEffect(() => {
    async function load() {
      if (!selectedArea) return;

      setLoadingGroups(true);
      setSelectedAreaGroup(null);
      setSelectedLocation(null);
      setGroupOptions(await listGroupNames(selectedArea.id));
      setLoadingGroups(false);
    }

    load();
  }, [selectedArea]);

  // Load locations
  useEffect(() => {
    async function load() {
      if (!selectedAreaGroup) return;

      setLoadingLocations(true);
      setSelectedLocation(null);
      setLocationOptions(
        await listLocationNames(
          selectedAreaGroup.id,
          selectedAreaGroup.locationType
        )
      );
      setLoadingLocations(false);
    }

    load();
  }, [selectedAreaGroup]);

  useEffect(() => {
    setIsMultipleSelected(selectedFiles && selectedFiles.length > 1);
  }, [selectedFiles]);

  function Footer() {
    return (
      <div>
        <Button severity={"danger"} outlined onClick={onClose}>
          <LanguageProvider id={"gen.cancel"} />
        </Button>
        <Button
          loading={submitting}
          disabled={!selectedFiles}
          onClick={uploadImage}
        >
          <LanguageProvider id={"gen.send"} />
        </Button>
      </div>
    );
  }

  return (
    <Dialog
      appendTo={"self"}
      visible={props.visible}
      onHide={onClose}
      draggable={false}
      header={LanguageProviderWithoutContext({
        id: "inventory.image.upload.header",
        currentLanguage,
      })}
      footer={Footer}
    >
      <div className="image-upload-content">
        {!isMultipleSelected && inventory && inventory.areas && (
          <div className="location-selection">
            <Dropdown
              placeholder={LanguageProviderWithoutContext({
                id: "gen.area",
                currentLanguage,
              })}
              value={selectedArea}
              options={inventory.areas}
              optionLabel={"name"}
              filter
              disabled={isMultipleSelected}
              onChange={(e) => setSelectedArea(e.value)}
            ></Dropdown>
            <Dropdown
              options={groupOptions}
              value={selectedAreaGroup}
              optionLabel={"name"}
              onChange={(e) => setSelectedAreaGroup(e.value)}
              disabled={!selectedArea || isMultipleSelected}
              filter
              placeholder={LanguageProvider({ id: "gen.street" })}
              loading={loadingGroups}
            ></Dropdown>
            <Dropdown
              options={locationOptions}
              value={selectedLocation}
              optionLabel={"name"}
              onChange={(e) => setSelectedLocation(e.value)}
              disabled={!selectedAreaGroup || isMultipleSelected}
              filter
              placeholder={LanguageProvider({ id: "gen.location" })}
              loading={loadingLocations}
            ></Dropdown>
          </div>
        )}
        <div className={"file-drop-area"}>
          {selectedFiles?.length > 1 && (
            <div className={"multiple-files-msg"}>
              <p>{selectedFiles?.length} arquivos selecionados.</p>
              <p
                className={"remove-all"}
                onClick={() => setSelectedFiles(null)}
              >
                Remover tudo
              </p>
            </div>
          )}

          {selectedFiles?.length === 1 && (
            <div className={"selected-image-wrapper"}>
              <div
                className="image-preview"
                style={{
                  background: `url(${URL.createObjectURL(selectedFiles[0])}`,
                }}
              />
              <span>
                <Button
                  severity={"secondary"}
                  onClick={() => setSelectedFiles(null)}
                >
                  <Icon icon={"trash-01"} size={20} color={undefined}></Icon>{" "}
                </Button>
                <p>{selectedFiles[0].name}</p>
              </span>
            </div>
          )}

          {(!selectedFiles || selectedFiles?.length === 0) && (
            <FileUploadBox
              accept={".png,.jpg,.jpeg"}
              onMultipleFileSelect={setSelectedFiles}
              onFileSelect={undefined}
              multiple={true}
            ></FileUploadBox>
          )}
        </div>
      </div>
      <Dialog
        header={<LanguageProvider id={"gen.wait"} />}
        visible={submitting}
        modal
        closable={false}
        onHide={() => {}}
      >
        <div className="flex flex-column align-items-center">
          <p>
            <LanguageProvider id={"inventory.image.wait.upload.description"} />
          </p>
          <ProgressBar
            value={progressValue}
            displayValueTemplate={() => (
              <React.Fragment>
                {indexCurrentImageUploading}/{selectedFiles?.length}
              </React.Fragment>
            )}
          />
        </div>
      </Dialog>
    </Dialog>
  );
}
