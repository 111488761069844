import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface InlineZoomImageProps {
  src: string;
  alt?: string;
  className?: string;
  imageClassName?: string;
}

export const DcpInlineZoomImage = forwardRef(
  ({ src, alt, className, imageClassName }: InlineZoomImageProps, ref) => {
    const transformRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      zoomIn: transformRef.current?.zoomIn,
      zoomOut: transformRef.current?.zoomOut,
      resetTransform: transformRef.current?.resetTransform,
    }));

    return (
      <TransformWrapper
        ref={transformRef}
        initialScale={1}
        minScale={1}
        maxScale={5}
      >
        <div
          className={className}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TransformComponent
            contentStyle={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={src}
              alt={alt}
              className={imageClassName}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "10px",
                marginBottom: "10px",
                width: "100%",
                height: "100%",
                cursor: "grab",
              }}
            />
          </TransformComponent>
        </div>
      </TransformWrapper>
    );
  }
);
