import "./PerformancePercentage.scss";

import { useContext, useEffect, useRef, useState } from "react";

import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { getConfigValue } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";

export default function PerformancePercentage(configs = []) {
  const useMockData = useContext(UseMockDataContext);
  const [currentValue, setCurrentValue] = useState(0);
  const [thresholdValues, setThresholdValues] = useState([0, 0]);

  const parentRef = useRef(null);

  // mock value
  useEffect(() => {
    if (useMockData) {
      setCurrentValue(52);
      setThresholdValues([70, 40]);
    } else {
      // get value from api
      setThresholdValues(
        getConfigValue(configs, "persentage-threshold") ?? [0, 0]
      );
    }
  }, [useMockData, configs]);

  let mainColor = "var(--red-500)";
  if (currentValue >= thresholdValues[0]) mainColor = "var(--green-500)";
  else if (currentValue >= thresholdValues[1]) mainColor = "var(--yellow-500)";

  function percentageInRadians(percentage) {
    return percentage * (Math.PI / 100);
  }

  function getPath(percentage) {
    const gaugeRadius = 107;
    const startingY = 120;
    const startingX = 12;

    const zeroBasedY = gaugeRadius * Math.sin(percentageInRadians(percentage));
    const y = -zeroBasedY + startingY;
    const zeroBasedX = gaugeRadius * Math.cos(percentageInRadians(percentage));
    const x = -zeroBasedX + gaugeRadius + startingX;

    return `M ${startingX} ${startingY}
           A ${gaugeRadius} ${gaugeRadius} 0 0 1 ${x} ${y}
           `;
  }

  function getInnerPath(percentage) {
    const gaugeRadius = 107 - 24;
    const startingY = 120;
    const startingX = 12 + 24;

    const zeroBasedY = gaugeRadius * Math.sin(percentageInRadians(percentage));
    const y = -zeroBasedY + startingY;
    const zeroBasedX = gaugeRadius * Math.cos(percentageInRadians(percentage));
    const x = -zeroBasedX + gaugeRadius + startingX;

    return `M ${startingX} ${startingY}
           A ${gaugeRadius} ${gaugeRadius} 0 0 1 ${x} ${y}
           `;
  }

  return (
    <div className="performance-percentage-wrapper" ref={parentRef}>
      <div className="performance-percentage" ref={parentRef}>
        <div className="label-container">
          <p className="percentage">{currentValue.toFixed(1)}%</p>
        </div>
        <svg width={240} height={137} xmlns="http://www.w3.org/2000/svg">
          <path
            d="M 12 120
          A 107 107 0 0 1 226 120
          "
            stroke="#F5F5FD"
            strokeWidth="24"
            strokeLinecap="round"
            fill="none"
          />
          <path
            d={getPath(currentValue)}
            stroke={mainColor}
            strokeWidth="24"
            strokeLinecap="round"
            fill="none"
          />
          <g id="svg-graph-meter-value"></g>
        </svg>
        <svg width={240} height={137} xmlns="http://www.w3.org/2000/svg">
          <path
            d={getInnerPath(100)}
            stroke="var(--green-500)"
            strokeWidth="6"
            fill="none"
            strokeLinecap="round"
          />
          <path
            d={getInnerPath(thresholdValues[0])}
            stroke={"var(--yellow-500)"}
            strokeWidth="6"
            fill="none"
            strokeLinecap="round"
          />
          <path
            d={getInnerPath(thresholdValues[1])}
            stroke={"var(--red-500)"}
            strokeWidth="6"
            fill="none"
            strokeLinecap="round"
          />

          <g id="svg-graph-meter-value"></g>
        </svg>
      </div>
    </div>
  );
}
