import "./scss/area-map-list.scss";
import React, { useContext, useEffect, useState } from "react";
import PageHeader from "~/shared/components/page-header/page-header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "~/context/ToastContext";
import { Button } from "~/shared/components/dcp-button";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { useFormik } from "formik";
import ColorPickerButton from "~/shared/components/dcp-color-picker-button";
import { ThemeContext } from "~/app";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export function AreaMapList(): JSX.Element {
  const { currentLanguage } = useLanguageContext();
  const [areas, setAreas] = useState<any>([]);
  const [selectedArea, setSelectedArea] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const { showToast } = useToastContext();
  const dcpAxiosService = useDcpAxiosService();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { currentTheme } = useContext(ThemeContext);

  interface AreaMap {
    id: 0;
    name: string;
    color: string;
  }

  const form = useFormik<AreaMap>({
    initialValues: {
      id: 0,
      name: "",
      color: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.name) {
        errors["name"] = (
          <LanguageProvider id={"area.map.create.name.validation.error"} />
        );
      }
      return errors;
    },
    onSubmit: (values) =>
      values.id > 0 ? onUpdateArea(values) : onCreateArea(values),
  });

  async function onCreateArea(values) {
    setSubmitting(true);

    if (values.color == "") values.color = "#3402F8";

    const { data: responseData, status } = await dcpAxiosService.post(
      `${settings.Urls.Rest.AreaMap}/create`,
      values,
      "Platform"
    );

    if (status === 200) {
      showToast({
        severity: "success",
        message: <LanguageProvider id={"gen.message.area.map.created"} />,
      });
      form.resetForm();
      loadAreas();
      setIsEditModalVisible(false);
    } else {
      showToast({
        severity: "error",
        message: <LanguageProvider id={"gen.error"} />,
      });
      form.resetForm();
    }
    setSubmitting(false);
  }

  async function onUpdateArea(values) {
    const { data: responseData, status } = await dcpAxiosService.post(
      `${settings.Urls.Rest.AreaMap}/update-area`,
      values,
      "Platform"
    );

    if (status === 200) {
      showToast({
        severity: "success",
        message: LanguageProviderWithoutContext({
          id: "gen.message.area.map.updated",
          currentLanguage,
        }),
      });
      form.resetForm();
      loadAreas();
      setIsEditModalVisible(false);
    } else {
      showToast({
        severity: "error",
      });
      form.resetForm();
    }
  }

  async function loadAreas() {
    try {
      setLoading(true);
      const { data } = await dcpAxiosService.get(
        `${settings.Urls.Rest.AreaMap}/list-areas`,
        "Platform"
      );

      if (Array.isArray(data.data)) {
        setAreas(data.data);
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id="unhandled.error" />,
      });
    }
    setLoading(false);
  }

  async function onDelete() {
    try {
      const { status } = await dcpAxiosService.delete(
        `${settings.Urls.Rest.AreaMap}/delete?id=${selectedArea}`,
        "Platform"
      );
      if (status === 200) {
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "gen.message.area.map.deleted",
            currentLanguage,
          }),
        });
        await loadAreas();
      } else {
        showToast({
          severity: "error",
        });
      }

      setDeleteConfirmationOpen(false);
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id="unhandled.error" />,
      });
    }
  }

  const isCreateAreaMapFormValid = (name) => {
    return !!form.errors[name];
  };

  const GetNameFormError = ({ name }) => {
    return isCreateAreaMapFormValid(name) ? (
      <small className="p-error">{form.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    loadAreas();
  }, []);

  function headerActions() {
    return (
      <div className="header-actions">
        <Button
          onClick={() => {
            form.resetForm();
            setIsEditModalVisible(true);
          }}
        >
          <LanguageProvider id="area.map.create" />
        </Button>
      </div>
    );
  }

  return (
    <div className="area-map-list">
      <div className="table-wrapper">
        <PageHeader
          titleTemplate={undefined}
          recordsCount={areas.length}
          onReturn={undefined}
          actions={headerActions}
          title={LanguageProvider({ id: "product.drone.storage" })}
        ></PageHeader>
        <div className="datatable-container">
          <DataTable
            value={areas}
            loading={loading}
            paginator
            rows={settings.RowsPerPageOptions.Default}
            scrollable
            scrollHeight="flex"
            rowsPerPageOptions={settings.RowsPerPageOptions.Options}
            emptyMessage={<LanguageProvider id="gen.no.registers" />}
          >
            <Column field="name" header={<LanguageProvider id="gen.name" />} />
            <Column
              field="color"
              header={<LanguageProvider id="gen.color" />}
              body={colorColumnTemplate}
            />
            <Column
              field="locations"
              header={<LanguageProvider id="gen.locations" />}
            />

            <Column
              headerStyle={{ width: "10%", minWidth: "100px" }}
              body={(rowData) => {
                return (
                  <div className="column-wrapper">
                    <div className="column-actions">
                      <div
                        className="icon-wrapper"
                        onClick={() => navigate("edit/" + rowData.id)}
                      >
                        <Icon
                          icon="map-01"
                          size={20}
                          color={currentTheme.tableIconColor}
                          className="icon-row"
                        />
                      </div>
                      <div
                        className="icon-wrapper"
                        onClick={() => {
                          form.setValues({
                            id: rowData.id,
                            name: rowData.name,
                            color: rowData.color,
                          });
                          setIsEditModalVisible(true);
                        }}
                      >
                        <Icon
                          icon="edit-02"
                          size={20}
                          color={currentTheme.tableIconColor}
                          className="icon-row"
                        />
                      </div>
                    </div>
                    <div className="column-actions">
                      <div
                        className="icon-wrapper"
                        onClick={() => {
                          setSelectedArea(rowData.id);
                          setDeleteConfirmationOpen(true);
                        }}
                      >
                        <Icon
                          icon="trash-02"
                          size={20}
                          color={currentTheme.tableIconColor}
                          className="icon-row"
                        />
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </DataTable>
        </div>
      </div>
      <ModalConfirmation
        isOpen={deleteConfirmationOpen}
        modalTitle={<LanguageProvider id="gen.exclude.button" />}
        bodyMessage={
          <LanguageProvider id="gen.message.confirm.delete.message" />
        }
        isDelete={true}
        onConfirm={onDelete}
        onCancel={() => setDeleteConfirmationOpen(false)}
      />

      <Dialog
        visible={isEditModalVisible}
        appendTo={"self"}
        header={<LanguageProvider id="area.map.edit" />}
        onHide={() => setIsEditModalVisible(false)}
        className="area-map-create-dialog"
      >
        <div className="edit-dialog">
          <div className="form-container">
            <Form>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="gen.color" />}
                  className="color-picker"
                >
                  <ColorPickerButton
                    color={form.values.color}
                    onChange={(e) => form.setFieldValue("color", e)}
                  ></ColorPickerButton>
                </InputContainer>
                <InputContainer label={<LanguageProvider id="gen.name" />}>
                  <InputText
                    value={form.values.name}
                    onChange={(e) => form.setFieldValue("name", e.target.value)}
                  ></InputText>
                </InputContainer>
              </FormRow>
            </Form>
          </div>

          <div className="button-container">
            <Button
              text
              appearance="secondary"
              onClick={() => setIsEditModalVisible(false)}
            >
              <LanguageProvider id="gen.cancel.button" />
            </Button>
            <Button onClick={() => form.submitForm()}>
              <LanguageProvider id="gen.save.button" />
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export function colorColumnTemplate({ color }) {
  return (
    <div className="color-column-template">
      <span
        className="color-circle"
        style={{ background: color.padStart(7, "#") }}
      ></span>
      <span>{color.replace("#", "").toUpperCase()}</span>
    </div>
  );
}
