import "./scss/area-counts.scss";

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Area } from "~/shared/interfaces";
import { AuditCountDialog } from "./components/audit-count-dialog";
import { Badge } from "primereact/badge";
import { Button } from "~/shared/components/dcp-button";
import { CountsTable } from "./counts-table";
import Icon from "~/shared/components/icons";
import { InputText } from "~/shared/components/dcp-form";
import { InventoryContext } from "~/pages/inventory/inventory/inventory-details/inventory-details";
import LanguageProvider from "~/shared/components/language-provider.jsx";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { Location } from "./location";
import { Map } from "./map";
import { MultiSelect } from "primereact/multiselect";
import { ThemeContext } from "~/app";
import { UnprocessedImageAuditDialog } from "./components/unprocessed-images-dialog";
import { classNames } from "primereact/utils";
import { compareNormalized } from "~/shared/helpers/utils";
import { getInventoryLocations } from "~/services/api";
import { listAreaGroups } from "~/services/api/platform/area";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useLanguageContext } from "~/context/LanguageContext";

export const InventoryCountsContext = createContext({
  setAuditDialogOpen: (open: boolean, location?: string) => {},
  refreshCounts: () => {},
});

const statusOptions = [
  {
    code: 1,
    status: "audited",
    label: <LanguageProvider id={"inventory.audited"} />,
  },
  {
    code: 2,
    status: "matches",
    label: <LanguageProvider id={"inventory.matches"} />,
  },
  {
    code: 3,
    status: "divergent",
    label: <LanguageProvider id={"inventory.divergent"} />,
  },
];

export function AreaCounts({ loading }) {
  const { currentTheme } = useContext(ThemeContext);
  const { currentLanguage } = useLanguageContext();
  const {
    inventory,
    refreshUnknownImageCounterBadge,
    setRefreshUnknownImageCounterBadge,
  } = useContext(InventoryContext);
  const dcpAxiosService = useDcpAxiosService();

  // Data
  const [selectedAreaIndex, setSelectedAreaIndex] = useState(0);
  const [selectedArea, setSelectedArea] = useState<Area>();
  const [groups, setGroups] = useState(null);
  const [formattedLocations, setFormattedLocations] = useState(null);
  const [locations, setLocations] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState([1, 2, 3]);
  const [unprocessedImagesQuantity, setUnprocessedImagesQuantity] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [auditDialogVisible, setAuditDialogVisible] = useState(false);
  const [
    auditUnprocessedImagesDialogVisible,
    setAuditUnprocessedImagesDialogVisible,
  ] = useState(false);
  const [selectedAuditLocation, setSelectedAuditLocation] =
    useState<string>(null);

  // Loading indicators
  const [loadingGroups, setLoadingGroups] = useState(false);

  // Element visibility
  const [fullScreen, setFullScreen] = useState(false);
  const [gettingLocations, setGettingLocations] = useState(true);
  const [oppenedLocationPanelId, setOppenedLocationPanelId] = useState(null);
  const [selectedPanel, setSelectedPanel] = useState("list");

  // Calculated values
  const areaAccurracy = useMemo(() => getAreaAccuracy(locations), [locations]);
  const completedStatusRatio = useMemo(
    () => getAreaCompletedStatusRatio(locations),
    [locations]
  );

  function onExceptionClick(location) {
    setSelectedPanel("list");
    setOppenedLocationPanelId(location.id);
  }

  const getInventoryUnprocessedImages = async () => {
    try {
      const response = await dcpAxiosService.get(
        `${settings.Urls.Rest.Inventory}/location-unprocessed-images/`,
        "Inventory",
        {
          params: {
            idInventory: inventory?.id,
          },
        }
      );
      const data = response.data;
      if (data.status) {
        setUnprocessedImagesQuantity(data.data.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function loadLocationCounts(areaId, inventoryId) {
    try {
      if (!areaId || !inventoryId) return;

      setLoadingGroups(true);
      setGettingLocations(true);
      const data = await getInventoryLocations(areaId, inventoryId);

      if (Array.isArray(data)) {
        setFormattedLocations(groupByGroupRackName(data));
        setLocations(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setGettingLocations(false);
      }, 2000);
      setLoadingGroups(false);
    }
  }

  async function refreshCounts() {
    try {
      if (!selectedArea || !inventory) return;
      await loadLocationCounts(selectedArea.id, inventory.id);
    } catch (error) {}
  }

  useEffect(() => {
    const loadGroups = async () => {
      try {
        setLoadingGroups(true);
        const area = inventory?.areas[selectedAreaIndex];
        setSelectedArea(area);
        if (!area) return;

        const data = await listAreaGroups(area.id);

        if (Array.isArray(data)) {
          setGroups(data);
          await loadLocationCounts(area.id, inventory.id);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingGroups(false);
      }
    };
    getInventoryUnprocessedImages();
    loadGroups();
  }, [selectedAreaIndex, inventory]);

  useEffect(() => {
    if (inventory && inventory.areas && inventory.areas.length > 0) {
      setSelectedAreaIndex(0);
      getInventoryUnprocessedImages();
    }
  }, [inventory]);

  useEffect(() => {
    if (refreshUnknownImageCounterBadge) {
      setTimeout(() => {
        getInventoryUnprocessedImages();
      }, 10000);

      setRefreshUnknownImageCounterBadge(false);
    }
  }, [refreshUnknownImageCounterBadge]);

  return (
    <div
      className={`inventory-details-content ${
        fullScreen ? "fullscreen" : null
      }`}
    >
      <div className="backdrop">
        <div className="header">
          {loading ? (
            <LoadingIndicator></LoadingIndicator>
          ) : (
            <>
              <div className="area-selection">
                <div className="selector">
                  <div
                    className="icon"
                    onClick={() => {
                      if (selectedAreaIndex != null && selectedAreaIndex > 0) {
                        setSelectedAreaIndex(selectedAreaIndex - 1);
                      }
                    }}
                  >
                    <Icon
                      icon="chevron-left"
                      size={24}
                      color={
                        selectedAreaIndex != null && selectedAreaIndex > 0
                          ? "#344054"
                          : "#D0D5DD"
                      }
                    />
                  </div>
                  <span className="name">
                    {selectedAreaIndex != null &&
                    inventory.areas &&
                    inventory.areas.length > 0
                      ? inventory.areas[selectedAreaIndex].name
                      : null}
                  </span>
                  <div
                    className="icon"
                    onClick={() => {
                      if (
                        selectedAreaIndex != null &&
                        selectedAreaIndex < inventory.areas.length - 1
                      ) {
                        setSelectedAreaIndex(selectedAreaIndex + 1);
                      }
                    }}
                  >
                    <Icon
                      icon="chevron-right"
                      size={24}
                      color={
                        selectedAreaIndex != null &&
                        selectedAreaIndex < inventory.areas.length - 1
                          ? "#344054"
                          : "#D0D5DD"
                      }
                    />
                  </div>
                </div>
                <div className="area-header-actions-wrapper">
                  <Button
                    label={LanguageProviderWithoutContext({
                      id: "gen.audit",
                      currentLanguage,
                    })}
                    className="p-button-primary"
                    icon={
                      <Icon
                        color={"white"}
                        icon={"file-search-01"}
                        size={undefined}
                      />
                    }
                    onClick={() => {
                      setSelectedAuditLocation(null);
                      setAuditDialogVisible(true);
                    }}
                    loading={gettingLocations}
                    disabled={locations?.length === 0}
                  />
                  <Button
                    label={
                      <div className="unprocessed-image-button-label-wrapper">
                        {LanguageProviderWithoutContext({
                          id: "gen.unprocessed.image",
                          currentLanguage,
                        })}
                        <Badge
                          value={unprocessedImagesQuantity}
                          className="badge-image-counts"
                          severity="danger"
                        />
                      </div>
                    }
                    className="p-button p-button-secondary unprocessed-image-button"
                    icon={
                      <Icon
                        color={currentTheme.secondaryButtonFontColor}
                        icon={"image-x"}
                        size={undefined}
                      />
                    }
                    onClick={() => setAuditUnprocessedImagesDialogVisible(true)}
                    loading={gettingLocations}
                  />
                  <div
                    className="icon"
                    onClick={() => setFullScreen(!fullScreen)}
                  >
                    <Icon
                      icon={fullScreen ? "minimize-01" : "maximize-01"}
                      size={18}
                      color={currentTheme.iconDefaultColor}
                    />
                  </div>
                </div>
              </div>
              <div className="search">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    className="search-input"
                    placeholder={LanguageProviderWithoutContext({
                      id: "search.field.placeholder",
                      currentLanguage,
                    })}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    id={undefined}
                    name={undefined}
                    value={undefined}
                    onBlur={undefined}
                    style={undefined}
                    disabled={undefined}
                    readOnly={undefined}
                    type={undefined}
                  ></InputText>
                </span>
                <MultiSelect
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.value)}
                  options={statusOptions}
                  optionLabel="label"
                  optionValue="code"
                  display="chip"
                />
                <div className="locations">
                  <span className="title">
                    <LanguageProvider id="area.map.locations" />:{" "}
                  </span>
                  <span className="value">{completedStatusRatio}</span>
                </div>
                <div className="accuracy">
                  <span className="title">
                    <LanguageProvider id="inventory.accuracy" />:{" "}
                  </span>
                  <span className="value">{areaAccurracy}%</span>
                </div>
                <div className="buttons">
                  <Button
                    className="p-button-text"
                    onClick={() => setSelectedPanel("list")}
                  >
                    <Icon icon="list" color="#4146FF" size={20} />
                  </Button>
                  {/*<Button*/}
                  {/*  className="p-button-text"*/}
                  {/*  onClick={() => setSelectedPanel("map")}*/}
                  {/*>*/}
                  {/*  <Icon icon="map-01" color="#4146FF" size={20} />*/}
                  {/*</Button>*/}
                  <Button
                    className="p-button-text"
                    onClick={() => setSelectedPanel("table")}
                  >
                    <Icon icon="table" color="#4146FF" size={20} />
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>

        {loadingGroups && <LoadingIndicator />}

        {!loadingGroups && selectedPanel === "list" && (
          <div className="main-content">
            {formattedLocations &&
              Object.keys(formattedLocations).length === 0 && (
                <h3 className="no-location-counts">
                  <LanguageProvider id="inventory-no-location-counts" />
                </h3>
              )}
            {formattedLocations &&
              Object.keys(formattedLocations).map(
                (areaGroupName, areaIndex) => {
                  let _locations = formattedLocations[areaGroupName];
                  // Search filed filter
                  if (searchQuery.length > 0) {
                    _locations = _locations.filter((x) =>
                      compareNormalized(x.location.name, searchQuery)
                    );
                  }

                  // Status selection filter
                  _locations = _locations.filter((x) =>
                    selectedStatus.includes(x.locationStatus)
                  );

                  if (_locations.length === 0) return null;

                  return (
                    <div key={"isle-" + areaIndex} className="aisle">
                      <p className="label">{"Rua " + areaGroupName}</p>
                      {_locations.map((location, locIndex) => {
                        return (
                          <InventoryCountsContext.Provider
                            value={{
                              setAuditDialogOpen: (
                                visible: boolean,
                                location?: string
                              ) => {
                                setSelectedAuditLocation(location);
                                setAuditDialogVisible(visible);
                              },
                              refreshCounts: refreshCounts,
                            }}
                          >
                            <Location
                              location={location}
                              key={
                                "location-panel-" + areaIndex + "-" + locIndex
                              }
                              oppened={oppenedLocationPanelId === location.id}
                            />
                          </InventoryCountsContext.Provider>
                        );
                      })}
                    </div>
                  );
                }
              )}
          </div>
        )}

        {!loadingGroups && selectedPanel === "map" && (
          <Map
            area={inventory.areas[selectedAreaIndex]}
            groups={groups}
            exceptions={getLocationsWithDivergency(locations)}
            onExceptionClick={onExceptionClick}
          />
        )}

        {!loadingGroups && selectedPanel === "table" && <CountsTable />}
      </div>

      <UnprocessedImageAuditDialog
        onClose={async () => {
          setAuditUnprocessedImagesDialogVisible(false);
          await getInventoryUnprocessedImages();
        }}
        key={selectedAreaIndex}
        visible={auditUnprocessedImagesDialogVisible}
      />

      <AuditCountDialog
        isOpen={auditDialogVisible}
        onClose={() => {
          setAuditDialogVisible(false);
          loadLocationCounts(
            inventory?.areas[selectedAreaIndex].id,
            inventory.id
          );
        }}
        area={selectedArea}
        locations={locations}
        selectedLocation={selectedAuditLocation}
      />
    </div>
  );
}

function groupByGroupRackName(array) {
  return array.reduce((grouped, item) => {
    const name = item.areaGroupRoadName;
    if (!grouped[name]) {
      grouped[name] = [];
    }
    grouped[name].push(item);

    grouped[name] = grouped[name].sort((a, b) =>
      a.location.name.localeCompare(b.location.name)
    );

    return grouped;
  }, {});
}

function getAreaAccuracy(locations) {
  try {
    if (!locations) return 0;

    const locationsTotal = locations.reduce((acc, location) => {
      return acc + location.accuracyPercent;
    }, 0);

    return locationsTotal > 0
      ? Math.round(locationsTotal / locations.length)
      : 0;
  } catch (error) {
    console.error(error);
  }
}

function getLocationsWithDivergency(locations) {
  try {
    if (!locations) return [];
    return locations.filter((location) => location.locationStatus !== 1);
  } catch (error) {
    console.error(error);
  }
}

function getAreaCompletedStatusRatio(locations) {
  try {
    if (!locations) return;

    const locationsCompleted = locations.reduce((acc, location) => {
      if (location.locationStatus === 1) return acc + 1;
      return acc;
    }, 0);

    return `${locationsCompleted}/${locations.length}`;
  } catch (error) {
    console.error(error);
  }
}
