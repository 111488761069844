import React, { useContext } from "react";

import IcoMoon from "react-icomoon";
import IconSet from "../../theme/fonts/selection.json";
import { ThemeContext } from "../../app";

type IconProps = {
  icon: string;
  size?: string | number;
  color?: string;
  className?: string;
  onClick?: () => void;
};

const Icon: React.FC<IconProps> = ({
  icon,
  size,
  color,
  className = "",
  onClick,
}) => {
  const { currentTheme } = useContext(ThemeContext) ?? {};

  const computedClassName = `${className ? className + " " : ""}dcp-icon`;

  return (
    <IcoMoon
      className={computedClassName}
      icon={icon}
      iconSet={IconSet}
      size={size ?? currentTheme?.iconDefaultSize ?? 24}
      style={{ color: color ?? currentTheme?.iconDefaultColor ?? "black" }}
      onClick={onClick}
    />
  );
};

export default Icon;
