import { Dialog, DialogProps } from "primereact/dialog";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import React, { useEffect, useState } from "react";
import { getUsers, loadInventoryDetails } from "~/services/api";

import { Button } from "~/shared/components/dcp-button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Icon from "~/shared/components/icons";
import { InputTextarea } from "primereact/inputtextarea";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import settings from "~/services/settings.json";
import showToast from "~/shared/components/toast-custom";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useFormik } from "formik";
import { useLanguageContext } from "~/context/LanguageContext";

type InventoryDetailsDialogProps = DialogProps & {
  inventoryId: number;
};

const InventoryDetailsDialog: React.FC<InventoryDetailsDialogProps> = ({
  ...props
}) => {
  const { currentLanguage } = useLanguageContext();
  const axiosService = useDcpAxiosService();

  const [loadingInventoryData, setLoadingInventoryData] = useState(false);
  const [inventory, setInventory] = useState(null);

  async function getInventoryDetails(inventoryId: number) {
    setLoadingInventoryData(true);
    try {
      const response = await loadInventoryDetails(inventoryId);
      if (response.status) {
        return response.data;
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: response.message,
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingInventoryData(false);
    }
    return null;
  }
  const [equipments, setEquipments] = useState([]);
  const [users, setUsers] = useState([]);

  async function loadDrones() {
    try {
      const { data, status } = await axiosService.post(
        settings.Urls.Rest.Equipment + "/list/drone",
        {
          first: 0,
          rows: 999999999,
          page: 0,
          sortField: null,
          sortOrder: 1, // 1 or -1
          filters: [],
        },
        "Inventory"
      );
      if (status === 200) {
        return data.data.equipments;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit: onSubmit,
    validate: validate,
  });

  async function onSubmit(values: any) {}
  async function validate(values: any) {}

  useEffect(() => {
    if (props.visible) {
      async function initialize() {
        try {
          const [inventory, drones, users] = await Promise.all([
            getInventoryDetails(props.inventoryId),
            loadDrones(),
            getUsers(),
          ]);

          setInventory(inventory);
          setUsers(users.data);
          setEquipments(drones);
          formik.setValues(inventory);
        } catch (error) {
          console.error("Error loading data:", error);
        }
      }

      initialize();
    }
  }, [props.visible]);

  console.log("Detalhes do inventario =>", inventory);
  console.log("Equipamenjtos =>", equipments);
  console.log("Users =>", users);

  return (
    <Dialog
      {...props}
      className={classNames({
        "inventory-details-dialog": true,
        [props.className]: true,
      })}
      style={{ width: "70vw" }}
      resizable={false}
      header={() => {
        return (
          <div className="inventory-details-dialog-header">
            <span>
              <LanguageProvider id="gen.inventory" />:
            </span>
            <span>
              {inventory?.code} - {inventory?.name}
            </span>
          </div>
        );
      }}
      footer={() => {
        return (
          <>
            <Button
              appearance="secondary"
              icon={"pi pi-times"}
              iconPos="right"
              label={LanguageProvider({ id: "gen.cancel" })}
              onClick={() => {
                formik.resetForm();
                props.onHide();
              }}
            />
            <Button
              appearance="primary"
              icon={"pi pi-save"}
              iconPos="right"
              label={LanguageProvider({ id: "gen.save" })}
            />
          </>
        );
      }}
    >
      {loadingInventoryData ? (
        <LoadingIndicator />
      ) : (
        <Form>
          <FormRow>
            <InputContainer label={<LanguageProvider id="gen.code" />} required>
              <InputText value={formik.values?.code} disabled />
            </InputContainer>
            <InputContainer label={<LanguageProvider id="gen.name" />} required>
              <InputText value={formik.values?.name} disabled />
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer label={"Tipo de inventário"}>
              <FormRow>
                <InputContainer label={"Drone"}>
                  <Checkbox checked={formik.values?.drone} />
                </InputContainer>
                <InputContainer label={"Manual"}>
                  <Checkbox checked={formik.values?.manual} />
                </InputContainer>
              </FormRow>
            </InputContainer>
            <InputContainer label={"Drones"}>
              <MultiSelect
                options={equipments}
                value={formik.values?.equipments}
                maxSelectedLabels={2}
                display="chip"
                optionLabel="name"
                filter
                selectAll
                disabled={!formik.values?.drone}
              />
            </InputContainer>
            <InputContainer label={"Usuários"}>
              <MultiSelect
                value={formik.values?.users}
                options={users}
                disabled={!formik.values?.manual}
                display="chip"
                filter
                selectAll
                maxSelectedLabels={3}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer label={<LanguageProvider id="gen.created.at" />}>
              <Calendar value={new Date(formik.values?.createdAt)} disabled />
            </InputContainer>
            <InputContainer
              label={<LanguageProvider id="inventory.programmed.to.date" />}
            >
              <Calendar
                value={new Date(formik.values?.programmedTo)}
                disabled
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer label={<LanguageProvider id="gen.description" />}>
              <InputTextarea value={formik.values?.description} rows={4} />
            </InputContainer>
          </FormRow>
          <DataTable
            className="dcp-table"
            value={formik.values?.codes}
            emptyMessage={<LanguageProvider id="gen.no.registers" />}
            rows={5}
            paginator
            stripedRows
            scrollHeight="flex"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            filterDisplay="menu"
          >
            <Column
              field=""
              header={
                inventory?.restrictionType === 2 ? (
                  <LanguageProvider id={"gen.sku"} />
                ) : (
                  <LanguageProvider id={"gen.location"} />
                )
              }
              filter
              sortable
              body={(rowData) => {
                return rowData;
              }}
            />
            <Column
              field=""
              header={<LanguageProvider id={"gen.actions"} />}
              body={() => {
                return (
                  <div className="actions">
                    <Button
                      className="p-button-plain p-button-text"
                      onClick={() => {}}
                    >
                      <Icon icon="trash-02" className="action-delete" />
                    </Button>
                  </div>
                );
              }}
            />
          </DataTable>
        </Form>
      )}
    </Dialog>
  );
};

export default InventoryDetailsDialog;
