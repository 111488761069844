import "./scss/auth-layout.scss";

import { Splitter, SplitterPanel } from "primereact/splitter";

import AuthSideMenu from "./nav/auth-side-menu";
import NavMenu from "./nav/nav-menu";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <div className="auth-layout">
      <div className="content">
        <NavMenu />
        <Splitter>
          <SplitterPanel
            className="auth-side-menu-splitter-panel-container"
            size={10}
            minSize={1}
          >
            <AuthSideMenu />
          </SplitterPanel>
          <SplitterPanel
            className="auth-content-splitter-panel-container"
            size={90}
          >
            <Outlet />
          </SplitterPanel>
        </Splitter>
      </div>
    </div>
  );
};

export default AuthLayout;
