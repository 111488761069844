import React from "react";
import { useEffect } from "react";
import InitialSetup from "~/pages/shared-modules/databases/initial-setup/initial-setup";
import DatabaseList from "~/pages/shared-modules/databases/list/database-list";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";

const IdentificacaoImpressaoInterfaceInventory = () => {
  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(
      settings.DatabaseProcessType.Inventory_IdentificationInterface
    );
  }, [loadDatabaseProductProcess]);

  return (
    <>
      {databaseProductProcessData?.status &&
        (databaseProductProcessData !== null ? (
          <DatabaseList
            id={databaseProductProcessData?.idDatabase}
            canEdit={false}
            hasDetails={
              databaseProductProcessData?.uriDetails.length > 0 ? true : false
            }
            uriDetails={databaseProductProcessData?.uriDetails}
            showIcon={false}
          />
        ) : (
          <InitialSetup />
        ))}
    </>
  );
};

export default IdentificacaoImpressaoInterfaceInventory;
