import { useEffect } from "react";
import DatabaseList from "~/pages/shared-modules/databases/list/database-list";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";

const SkusWarehouse = () => {
  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(settings.DatabaseProcessType.Warehouse_SKU);
  }, [loadDatabaseProductProcess]);

  return (
    <>
      {databaseProductProcessData && (
        <DatabaseList
          id={databaseProductProcessData.idDatabase}
          hasDetails={databaseProductProcessData.hasDetails}
          uriDetails={databaseProductProcessData.uriDetails}
          showIcon={false}
        />
      )}
    </>
  );
};

export default SkusWarehouse;
