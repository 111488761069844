import "./scss/b2b-order-item-details.scss";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DatabaseList from "~/pages/shared-modules/databases/list/database-list";
import GenericDatabaseHeader from "~/pages/wms/components/generic-database-header";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import OrderShippingVolumesDatabase from "./components/order-shipping-volumes";

const B2BOrderItem = () => {
  const { itemId } = useParams();
  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(
      settings.DatabaseProcessType.B2B_InterfaceOrderItem
    );
  }, [loadDatabaseProductProcess]);

  return (
    <>
      <div className="database-items-details">
        <div className="database-items-details-header">
          <div className="header-container">
            <h1>
              <LanguageProvider id="database.detail.stage.order" />
            </h1>
          </div>
          <GenericDatabaseHeader itemId={itemId} />
        </div>
      </div>
      <div className="database-items-details-container">
        <OrderShippingVolumesDatabase />
        {databaseProductProcessData && (
          <DatabaseList
            id={databaseProductProcessData.idDatabase}
            itemId={itemId}
            hasDetails={databaseProductProcessData.hasDetails}
            uriDetails={databaseProductProcessData.uriDetails}
            showIcon={false}
          />
        )}
      </div>
    </>
  );
};

export default B2BOrderItem;
