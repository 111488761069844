import "./scss/dcp-access-denied.scss";

import React, { useEffect, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import LanguageProvider from "~/shared/components/language-provider";
import { useNavigate } from "react-router-dom";

const DcpDeniedAccess = () => {
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <>
      {showContent ? (
        <div className="denied-access-wrapper">
          <div className="denied-access-body">
            <span className="denied-access-message">
              <LanguageProvider id={"gen.access.denied"} />
            </span>
            <span className="description-access-denied">
              <LanguageProvider id={"gen.access.denied.description"} />
            </span>
            {/* <img
            src={ForbiddenAccess}
            alt="Denied Access"
            className="denied-access-image"
          /> */}
            {/* <span className="description-mistake">
            <LanguageProvider id={"gen.access.denied.mistake.description"} />
          </span> */}

            <Button
              className="p-button p-button-back"
              label={<LanguageProvider id={"gen.back"} />}
              size="large"
              type="submit"
              onClick={goBack}
            />
          </div>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
};

export default DcpDeniedAccess;
