import "./scss/settings-document-print.scss";

import { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { useHeaderHeight } from "~/hooks/useHeaderHeight";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import { ConnectionsSubgroup } from "~/shared/components/settings-groups/connections-subgroups";
import { SettingGroup } from "~/shared/components/settings-groups/setting-group";
import { SettingSubgroup } from "~/shared/components/settings-groups/setting-subgroup";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { SettingsFieldsPrintSubgroup } from "~/shared/components/settings-groups/setting-fields-print-subgroup";
import { useToastContext } from "~/context/ToastContext";
import { ThemeContext, UserContext } from "~/app";

export function SettingsDocumentPrint() {
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [settingsGroups, setSettingsGroups] = useState([]);
  const [printFieldsSettings, setPrintFieldsSettings] = useState([]);
  const [updatedConnections, setUpdatedConnections] = useState([]);

  // Utils
  const headerHeight = useHeaderHeight();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  // Loading indicators
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const loadSettings = async () => {
    try {
      setLoading(true);
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Settings}/list-settings`,
        "Platform",
        {
          params: { applicationClientId },
        }
      );
      if (status === 200) {
        setSettingsGroups(data.data);
        setSelectedGroup(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const loadFieldsPrint = async () => {
    try {
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.fieldSettings}/list-all`,
        "PrintDocument"
      );
      if (status) {
        setPrintFieldsSettings(data.data);
      } else {
        setPrintFieldsSettings([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateSettingValue = (value, setting) => {
    try {
      let groups = [...settingsGroups];
      groups.forEach((group) => {
        group.subgroups.forEach((subgroup) => {
          subgroup.settings.forEach((_setting) => {
            if (_setting.defaultValues.id === setting.defaultValues.id) {
              _setting["modified"] = true;
              _setting.value = value;
            }
          });
        });
      });

      setSettingsGroups(groups);
    } catch (error) {
      console.error(error);
    }
  };

  const saveConnections = async () => {
    try {
      const payload = updatedConnections.filter(
        (connection) => connection.isModified
      );
      if (!payload.length) return;

      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.Connection,
        payload,
        "Platform"
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateSettings = async () => {
    try {
      let modifiedSettings = [];
      settingsGroups.forEach((groups) => {
        groups.subgroups.forEach((subgroup) => {
          subgroup.settings.forEach((setting) => {
            if (setting.modified) modifiedSettings.push(setting);
          });
        });
      });

      console.log(modifiedSettings);

      if (modifiedSettings.length > 0) {
        const { data, status } = await dcpAxiosService.post(
          `${settings.Urls.Rest.Settings}/update-settings`,
          modifiedSettings,
          "Platform"
        );

        if (status === 200) {
          return modifiedSettings;
        } else {
          console.log("Error updating settings");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadFieldsPrint();
    }
  };

  const onSave = async () => {
    try {
      setSaving(true);
      if (printFieldsSettings.length > 0) {
        try {
          // Update settings
          const settingsResponse = await handleUpdateSettings();

          // update field settings - Interface print group
          const { data, status } = await dcpAxiosService.post(
            `${settings.Urls.Rest.fieldSettings}/update`,
            printFieldsSettings,
            "PrintDocument"
          );

          if (status === 200 && settingsResponse && data.data.status) {
            showToast({
              severity: "success",
              message: "Configurações atualizadas com sucesso.",
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          loadFieldsPrint();
        }
      }

      await saveConnections();
    } catch (error) {
      console.error(error);
    }
    setSaving(false);
  };

  const predefinedSubgroups = {
    "print-interface": (
      <SettingsFieldsPrintSubgroup
        fields={printFieldsSettings ? printFieldsSettings : []}
        onChange={(fields) => setPrintFieldsSettings(fields)}
      />
    ),

    connections: (
      <ConnectionsSubgroup
        applicationCode="drone-inventory"
        onChange={(e) => setUpdatedConnections(e)}
      />
    ),
    undefined: null,
  };

  useEffect(() => {
    loadSettings();
    loadFieldsPrint();
  }, []);

  return (
    <>
      <div
        className="settings-print"
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
      >
        <div className="container">
          <div className="header">
            <h3>
              <LanguageProvider id="settings" />
            </h3>
          </div>
          <div className="settings-content">
            <div className="side-bar">
              <p>
                <LanguageProvider id="settings.description" />
              </p>
              {!loading && (
                <ul>
                  {settingsGroups?.length > 0 &&
                    settingsGroups?.map((group, index) => {
                      return (
                        <li
                          key={`${group}-${index}`}
                          className={
                            index === selectedGroup ? "selected" : null
                          }
                          onClick={() => setSelectedGroup(index)}
                        >
                          <LanguageProvider
                            id={`setting.group.${group.name}`}
                          />
                        </li>
                      );
                    })}
                </ul>
              )}
              {loading && (
                <>
                  <Skeleton width="240px" height="40px" className="skeleton" />
                  <Skeleton width="240px" height="40px" className="skeleton" />
                  <Skeleton width="240px" height="40px" className="skeleton" />
                </>
              )}
            </div>
            <div className="settings-container">
              <div className="container">
                {!loading && (
                  <>
                    {settingsGroups[selectedGroup]?.name && (
                      <SettingGroup name={settingsGroups[selectedGroup]?.name}>
                        {/* Render pre-defined subgroup */}
                        {
                          predefinedSubgroups[
                            settingsGroups[selectedGroup]?.name
                          ]
                        }

                        {/* Render dynamic subgroups */}
                        {settingsGroups[selectedGroup]?.subgroups &&
                          settingsGroups[selectedGroup]?.subgroups.map(
                            (subgroup, index) => {
                              return (
                                <SettingSubgroup
                                  key={index}
                                  subgroup={subgroup}
                                  onChange={updateSettingValue}
                                ></SettingSubgroup>
                              );
                            }
                          )}
                      </SettingGroup>
                    )}
                  </>
                )}
              </div>
              <div className="footer">
                <Button loading={saving} onClick={onSave}>
                  <LanguageProvider id="gen.save.button" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
