import "../scss/order-monitor-details-dialog.scss";
import settings from "~/services/settings.json";
import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "~/shared/components/dcp-button";
import { TabPanel, TabView } from "primereact/tabview";
import Icon from "~/shared/components/icons";
import {
  GetCarriers,
  GetOrderProcess,
} from "~/services/api/B2b/boticario/monitor-process";
import { Skeleton } from "primereact/skeleton";
import { Menu } from "primereact/menu";
import { DcpCustomZoomImage } from "~/shared/components/dcp-zoom-image";
import imageTest from "~/theme/media/assets/Location.png";
import { ThemeContext } from "~/app";
import { Dropdown } from "primereact/dropdown";
import OrderMonitorStatus from "~/shared/enums/B2b/OrderMonitorStatus";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useToastContext } from "~/context/ToastContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProvider from "~/shared/components/language-provider";

const ModalOrderMonitorDetails = ({ orderId, isOpen, onClose }) => {
  const { currentTheme } = useContext(ThemeContext);
  const { currentLanguage } = useLanguageContext();
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();

  const menuRef = useRef(null);
  const orderDetails = {
    logs: [
      "[2025-02-04 08:00:12] [INFO] Início do processo de envio - Pedido 123456",
      "[2025-02-04 08:00:12] [INFO] Início do processo de envio - Pedido 123456",
      "[2025-02-04 08:00:12] [INFO] Início do processo de envio - Pedido 123456",
      "[2025-02-04 08:00:12] [INFO] Início do processo de envio - Pedido 123456",
      "[2025-02-04 08:00:12] [INFO] Início do processo de envio - Pedido 123456",
      "[2025-02-04 08:00:12] [INFO] Início do processo de envio - Pedido 123456",
    ],
    timeline: [
      {
        step: "Ordem Criada",
        date: "07/08/2023 15:42",
        description: "Pedido registrado e aguardando processamento",
        status: "done",
      },
      {
        step: "Packing",
        date: "07/08/2023 16:42",
        description: "Separação e embalagem dos itens em andamento",
        status: "done",
      },
      {
        step: "Aguardando definição de transportadora",
        date: "07/08/2023 17:42",
        description: "Escolha do modal de transporte pendente",
        status: "undefined",
      },
      {
        step: "Transportadora definida",
        date: "07/08/2023 18:00",
        description: "",
        status: "undefined",
      },
      {
        step: "Etiquetagem",
        date: "07/08/2023 19:00",
        description: "",
        status: "undefined",
      },
    ],
  };
  const [loading, setLoading] = useState(true);
  const [loadingCarrier, setLoadingCarrier] = useState(true);

  const [orderProcessData, setOrderProcessData] = useState();
  const [locationImages, setLocationImages] = useState([
    {
      idUser: 1,
      images: [
        {
          url: imageTest,
        },
      ],
    },
  ]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);

  const getOrderProcess = async () => {
    if (!orderId) return;

    setLoading(true);
    try {
      const { data, status } = await GetOrderProcess(orderId);
      if (status) {
        setOrderProcessData(data);
        getCarriers(data?.carrier);
      }
    } catch (error) {
      console.error("Erro ao buscar os dados");
    } finally {
      setLoading(false);
    }
  };

  async function getCarriers(selectedOrderCarrier) {
    if (selectedOrderCarrier) {
      try {
        setLoadingCarrier(true);
        const { data, status } = await GetCarriers();
        if (status) {
          setCarriers(data);
          const selectedCarrier = data.find(
            (carrier) => carrier?.code === selectedOrderCarrier
          );
          setSelectedCarrier(selectedCarrier);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingCarrier(false);
      }
    }
  }

  function checkStatusToDisable(orderProcessStatus) {
    const monitorStatus = orderProcessStatus;

    switch (monitorStatus) {
      case OrderMonitorStatus.carrierError:
        return false;
      default:
        return true;
    }
  }

  function handleChangeCarrier(e) {
    setSelectedCarrier(carriers.find((carrier) => carrier.code === e.value));
  }

  // Todo
  function renderStatus(status) {
    const monitorStatus = status;
    switch (monitorStatus) {
      case OrderMonitorStatus.finished:
        return (
          <div className="processing-status-finished">
            <LanguageProvider id={"dcp.b2b.monitor.order.status.finished"} />
          </div>
        );
      case OrderMonitorStatus.pendingShipping:
        return (
          <div className="processing-status-pending">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pendent-shipping" />
          </div>
        );
      case OrderMonitorStatus.carrierDefinition:
        return (
          <div className="processing-status-carrier-definition">
            <LanguageProvider id="dcp.b2b.monitor.order.status.carrier-definition" />
          </div>
        );
      case OrderMonitorStatus.pendingIntegrationCarrier:
        return (
          <div className="processing-status-loaded">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-integration-carrier" />
          </div>
        );
      case OrderMonitorStatus.pendingPacking:
        return (
          <div className="processing-status-packing">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-packing" />
          </div>
        );
      case OrderMonitorStatus.carrierError:
        return (
          <div className="processing-status-carrier-error">
            <LanguageProvider id="dcp.b2b.monitor.order.status.carrier-error" />
          </div>
        );
      case OrderMonitorStatus.errorCarrierIntegration:
        return (
          <div className="processing-status-carrier-integration">
            <LanguageProvider id="dcp.b2b.monitor.order.status.error-carrier-integration" />
          </div>
        );
      case OrderMonitorStatus.pendingReport:
        return (
          <div className="processing-status-report-pending">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-report" />
          </div>
        );
      case OrderMonitorStatus.errorReport:
        return (
          <div className="processing-status-error-report">
            <LanguageProvider id="dcp.b2b.monitor.order.status.error-report" />
          </div>
        );
      case OrderMonitorStatus.pendingPrint:
        return (
          <div className="processing-status-print-pending">
            <LanguageProvider id="dcp.b2b.monitor.order.status.pending-print" />
          </div>
        );
      default:
        return <div>{status}</div>;
    }
  }

  const menuTemplate = (item, options, label, icon, color) => {
    return (
      <div
        onClick={(e) => options.onClick(e)}
        className={options.className}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          padding: "12px 16px",
        }}
      >
        <Icon icon={icon} color={color} size={16} />
        <p
          style={{ margin: 0, color, fontSize: "14px" }}
          className="menu-title"
        >
          <LanguageProvider id={label} />
        </p>
      </div>
    );
  };

  const menuItems = [
    {
      template: (item, options) => {
        return (
          <p
            style={{ padding: "12px 16px", margin: 0, fontWeight: 600 }}
            className="menu-title"
          >
            <LanguageProvider id="gen.menu" />
          </p>
        );
      },
    },
    { separator: true },
    {
      template: (item, options) =>
        menuTemplate(
          item,
          options,
          "gen.download",
          "download-01",
          currentTheme.textPrimary
        ),
      command: downloadSelectedImage,
    },
    {
      template: (item, options) =>
        menuTemplate(
          item,
          options,
          "gen.exclude.button",
          "trash-02",
          "#F9485B"
        ),
      command: () => deleteImage(),
    },
  ];

  function downloadSelectedImage() {
    let a = document.createElement("a");
    a.href = selectedImage.url;
    a.setAttribute("download", selectedImage.inventoryImage.fileName);
    a.click();
  }

  async function deleteImage() {
    try {
      setLoading(true);
      await dcpAxiosService.delete(
        settings.Urls.Rest.B2b + "/image",
        "Inventory",
        { params: { image: 5 } } // Todo
      );
      showToast({
        severity: "success",
        message: LanguageProviderWithoutContext({
          id: "image.deleted",
          currentLanguage,
        }),
      });
      // await loadImages();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrderProcess();
  }, [isOpen, orderId]);

  return (
    <Dialog
      className="order-monitor-details"
      header={
        <LanguageProvider id="dcp.b2b.monitor.order.details.dialog.title" />
      }
      headerClassName="order-monitor-details-header"
      visible={isOpen}
      style={{ width: "50vw" }}
      onHide={onClose}
      footer={
        <div className="footer">
          <Button label="OK" onClick={onClose} />
        </div>
      }
    >
      <TabView className="tab-view-container">
        <TabPanel
          header={
            <div className="tab-header">
              <Icon icon="file-06" />
              <span className="tab-panel-title">
                <LanguageProvider
                  id={"dcp.b2b.monitor.order.details.dialog.label.order"}
                />
              </span>
            </div>
          }
        >
          <div className="order-tab-container">
            <div className="order-left-side-wrapper">
              <div className="info-grid">
                <div className="info-grid-item">
                  <label className="o">
                    <LanguageProvider
                      id={"dcp.b2b.monitor.order.details.dialog.label.order"}
                    />
                  </label>
                  <span>{orderProcessData?.orderId}</span>
                </div>
                <div className="info-grid-item">
                  <label htmlFor="updateDate">
                    <LanguageProvider
                      id={"dcp.b2b.monitor.order.details.dialog.label.update"}
                    />
                  </label>
                  <span itemType="date">
                    {new Date(orderProcessData?.lastUpdated)
                      .toLocaleString()
                      .replace(",", "")}{" "}
                  </span>
                </div>
                <div className="info-grid-item">
                  <label htmlFor="status">
                    <LanguageProvider
                      id={"dcp.b2b.monitor.order.details.dialog.label.status"}
                    />
                  </label>
                  {renderStatus(orderProcessData?.status)}
                </div>
                <div className="info-grid-item">
                  <label htmlFor="city">
                    <LanguageProvider
                      id={"dcp.b2b.monitor.order.details.dialog.label.city"}
                    />
                  </label>
                  <span>{orderProcessData?.city}</span>
                </div>
                <div className="info-grid-item">
                  <label htmlFor="carrier">
                    <LanguageProvider
                      id={"dcp.b2b.monitor.order.details.dialog.label.carrier"}
                    />
                  </label>
                  <Dropdown
                    key={selectedCarrier?.code}
                    className="carrier-dropdown"
                    value={selectedCarrier && selectedCarrier?.code}
                    onChange={handleChangeCarrier}
                    loading={loadingCarrier}
                    options={carriers}
                    optionValue="code"
                    optionLabel="name"
                    disabled={checkStatusToDisable(orderProcessData?.status)}
                  />
                </div>
                <div className="info-grid-item">
                  <label htmlFor="trackNumber">
                    <LanguageProvider
                      id={
                        "dcp.b2b.monitor.order.details.dialog.label.track.number"
                      }
                    />
                  </label>
                  <a href={orderProcessData?.url} target="_blank">
                    <span
                      className="track-number-link"
                      style={{ textDecoration: "underline" }}
                    >
                      {orderProcessData?.trackNumber}
                    </span>
                  </a>
                </div>
              </div>
              <div className="log-process-wrapper">
                <label>Log</label>
                <span className="span-logs">
                  <li style={{ listStyleType: "none" }}>
                    {orderProcessData?.logProcess}
                  </li>
                </span>
              </div>
            </div>
            <div className="timeline-container">
              <ul className="timeline">
                {/* {orderDetails.timeline.map(
                  (
                    step,
                    index // Todo
                  ) => ( */}
                <>
                  <li className="timeline-item">
                    <div className="timeline-icon">
                      {orderProcessData?.status >= 0 ? (
                        <div
                          className="timeline-step-done"
                          style={{ backgroundColor: "#04AA77" }}
                        >
                          <Icon icon={"check"} color={"#ffff"} size={30} />
                        </div>
                      ) : (
                        <div className="timeline-blank-step">
                          <span className="timeline-step-number">1</span>
                        </div>
                      )}
                    </div>
                    <div className="timeline-content">
                      <strong>Ordem Criada</strong>
                      <p>{orderProcessData?.updateDate}</p>
                      <span className="description">
                        Description
                        {/* Todo ? */}
                      </span>
                    </div>
                  </li>
                  <div className="timeline-separator" />
                  <li className="timeline-item">
                    <div className="timeline-icon">
                      {orderProcessData?.status >= 10 ? (
                        <div
                          className="timeline-step-done"
                          style={{ backgroundColor: "#04AA77" }}
                        >
                          <Icon icon={"check"} color={"#ffff"} size={30} />
                        </div>
                      ) : (
                        <div className="timeline-blank-step">
                          <span className="timeline-step-number">2</span>
                        </div>
                      )}
                    </div>
                    <div className="timeline-content">
                      <strong>Packing</strong>
                      <p>{orderProcessData?.updateDate}</p>
                      <span className="description">Description</span>
                    </div>
                  </li>
                  <div className="timeline-separator" />
                  <li className="timeline-item">
                    <div className="timeline-icon">
                      {orderProcessData?.status === 20 && (
                        <div
                          className="timeline-step-done"
                          style={{ backgroundColor: "#04AA77" }}
                        >
                          <Icon icon={"check"} color={"#ffff"} size={30} />
                        </div>
                      )}
                      {orderProcessData?.status < 20 && (
                        <div className="timeline-blank-step">
                          <span className="timeline-step-number">1</span>
                        </div>
                      )}
                      {orderProcessData?.status === 21 && (
                        <div className="timeline-error-step">
                          <Icon icon="x-close" color="#fff" />
                        </div>
                      )}
                    </div>
                    <div className="timeline-content">
                      <strong>Aguardando Definição da Transportadora</strong>
                      <p>{orderProcessData?.updateDate}</p>
                      <span className="description">Description</span>
                    </div>
                  </li>
                  <div className="timeline-separator" />
                  <li className="timeline-item">
                    <div className="timeline-icon">
                      {orderProcessData?.status >= 30 ? (
                        <div
                          className="timeline-step-done"
                          style={{ backgroundColor: "#04AA77" }}
                        >
                          <Icon icon={"check"} color={"#ffff"} size={30} />
                        </div>
                      ) : (
                        <div className="timeline-blank-step">
                          <span className="timeline-step-number">4</span>
                        </div>
                      )}
                    </div>
                    <div className="timeline-content">
                      <strong>Transportadora Definida</strong>
                      <p>{orderProcessData?.updateDate}</p>
                      <span className="description">Description</span>
                    </div>
                  </li>
                  <div className="timeline-separator" />
                </>
                {/* )
                )} */}
              </ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel
          header={
            <div className="tab-header">
              <Icon icon="camera-02" />
              <span className="tab-panel-title">POD</span>
            </div>
          }
        >
          <div className="monitor-pictures-view">
            {loading ? (
              <div className="loading">
                <div className="skeleton row">
                  <Skeleton height="150px" width="160px"></Skeleton>
                  <Skeleton height="150px" width="160px"></Skeleton>
                  <Skeleton height="150px" width="160px"></Skeleton>
                  <Skeleton height="150px" width="160px"></Skeleton>
                </div>
                <div className="skeleton row">
                  <Skeleton height="150px" width="160px"></Skeleton>
                  <Skeleton height="150px" width="160px"></Skeleton>
                  <Skeleton height="150px" width="160px"></Skeleton>
                </div>
              </div>
            ) : (
              <div className="images-container">
                {locationImages
                  ? locationImages.map((locationUser, index) => {
                      return (
                        <div
                          key={"user-picture" + index}
                          className="user-wrapper"
                        >
                          <Menu
                            model={menuItems}
                            popup
                            ref={menuRef}
                            className="image-menu"
                          />
                          <div className="images row">
                            {locationUser.images &&
                              locationUser.images.map((image, index) => {
                                return (
                                  <div
                                    key={"image-" + index}
                                    className="image-wrapper"
                                  >
                                    <div className="image-header">
                                      <div className={"filename-wrapper"}>
                                        <Icon
                                          icon="image-01"
                                          size={20}
                                          className={"img-icon"}
                                        />
                                        <span className="filename">
                                          {"image.png"}
                                          {/*{image.inventoryImage.fileName}*/}
                                        </span>
                                      </div>
                                      <div
                                        className="icon"
                                        onClick={(e) => {
                                          setSelectedImage(image);
                                          menuRef.current.toggle(e);
                                        }}
                                      >
                                        <Icon
                                          icon="dots-vertical"
                                          size={20}
                                          color="#98A2B3"
                                        ></Icon>
                                      </div>
                                    </div>
                                    <DcpCustomZoomImage
                                      src={image.url}
                                      alt="Image"
                                      className="images-preview-thumbnail"
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            )}
          </div>
        </TabPanel>
        {orderProcessData?.status === OrderMonitorStatus.errorReport && (
          <TabPanel // Todo - Onclick?
            headerClassName="tab-report-track-number-header"
            headerTemplate={
              <Button
                // onClick={() )}
                label={
                  <LanguageProvider id="dcp.b2b.monitor.order.details.dialog.label.button.track.number" />
                }
              />
            }
          ></TabPanel>
        )}
      </TabView>
    </Dialog>
  );
};

export default ModalOrderMonitorDetails;
