import React, { useState, useRef, useEffect } from "react";
import "./scss/barcode-splitter.scss";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { InputText } from "primereact/inputtext";
import LanguageProvider from "~/shared/components/language-provider";
import JsBarcode from "jsbarcode";
import { ToggleButton } from "primereact/togglebutton";
import { Button } from "~/shared/components/dcp-button";

export function BarcodeSplitter() {
  const [barcode, setbarcode] = useState("");
  const [viewMode, setViewMode] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const barcodeInput = useRef(null);
  // Lógica para tratamento do valor

  function handleInputProcessing() {
    if (barcode.length < 1) return;

    let code = barcode.replace(/\u001D/g, "").trim();
    let result = [];

    // 010695513928185810240103252400304497113

    // 01087147298604331720251010302782602400302881738
    // 01087147299723031726070310344374362400304401535

    let indexStart = 0;

    // (01)
    if (code.slice(0, 2) === "01") {
      indexStart = 16;
      result.push({
        positionIndex: 0,
        nome: "01 - SKU / EAN",
        code: "01",
        valor: code.slice(2, 16),
        keyBind: "F1",
      });
    }

    // (17)
    if (code.slice(16, 18) === "17") {
      indexStart = 24;
      result.push({
        positionIndex: 4,
        nome: "17 - Data de Validade",
        code: "17",
        valor: code.slice(18, 24),
        keyBind: "F2",
      });
    }

    const udiIndex = code.lastIndexOf("240") ?? code.length;
    const udi = code.slice(udiIndex + 3);

    result.push({
      positionIndex: 2,
      nome: "240 - UDI",
      code: "240",
      valor: udi,
      keyBind: "F5",
    });

    if (code.slice(indexStart, indexStart + 2) === "10") {
      let lote = code.slice(indexStart + 2, udiIndex);

      const regex = /[a-zA-Z\W]/g;
      const test = regex.test(lote);

      if (!test && !lote.startsWith("0")) lote = lote.padStart(10, "0");

      result.push({
        positionIndex: 1,
        nome: "10 - LOTE",
        code: "10",
        valor: lote,
        keyBind: "F4",
      });
    } else if (code.slice(indexStart, indexStart + 2) === "21") {
      const serie = code.slice(indexStart + 2, udiIndex);
      result.push({
        positionIndex: 3,
        nome: "21 - Número de Série",
        code: "21",
        valor: serie,
        keyBind: "F3",
      });
    }

    result.sort((a, b) => a.positionIndex - b.positionIndex);
    console.log(result);

    setSelectedTab(0);
    setParsedData(result);
    setbarcode("");

    if (result.length > 0) {
      var item = result[0];
      navigator.clipboard.writeText(item.valor);
    }

    barcodeInput.current?.focus();
  }

  // const handleInputProcessing = () => {
  //   if (barcode.length > 0) {
  //     var text = barcode.replace(/\u001D/g, "");

  //     const result = [];
  //     let index = 0;

  //     while (index < text.length) {
  //       const prefix = text.slice(index, index + 2);
  //       let length = 0;

  //       switch (prefix) {
  //         case "01":
  //           length = 14;
  //           result.push({
  //             positionIndex: 0,
  //             nome: "01 - SKU / EAN",
  //             code: "01",
  //             valor: text.slice(index + 2, index + 2 + length),
  //             keyBind: "F1",
  //           });
  //           break;

  //         case "10":
  //           length = 8;
  //           result.push({
  //             positionIndex: 1,
  //             nome: "10 - LOTE",
  //             code: "10",
  //             valor: text.slice(index + 2, index + 2 + length),
  //             keyBind: "F4",
  //           });
  //           break;

  //         case "17":
  //           length = 6;
  //           result.push({
  //             positionIndex: 4,
  //             nome: "17 - Data de Validade",
  //             code: "17",
  //             valor: text.slice(index + 2, index + 2 + length),
  //             keyBind: "F2",
  //           });
  //           break;

  //         case "21":
  //           length = 6;
  //           result.push({
  //             positionIndex: 3,
  //             nome: "21 - Número de Série",
  //             code: "21",
  //             valor: text.slice(index + 2, index + 2 + length),
  //             keyBind: "F3",
  //           });
  //           break;

  //         case "24":
  //           length = 10;
  //           result.push({
  //             positionIndex: 2,
  //             nome: "240 - UDI",
  //             code: "240",
  //             valor: text.slice(index + 3, index + 3 + length),
  //             keyBind: "F5",
  //           });
  //           length = length + 1;
  //           break;

  //         default:
  //           console.log("Ultimo prefixo = ", prefix);
  //           break;
  //       }

  //       // Sai do loop caso o prefixo seja desconhecido
  //       if (
  //         prefix !== "01" &&
  //         prefix !== "21" &&
  //         prefix !== "17" &&
  //         prefix !== "10" &&
  //         prefix !== "24"
  //       ) {
  //         break;
  //       }

  //       index += 2 + length;
  //     }

  //     result.sort((a, b) => a.positionIndex - b.positionIndex);

  //     console.log(result);
  //     setSelectedTab(0);
  //     setParsedData(result);
  //     setbarcode("");

  //     if (result.length > 0) {
  //       var item = result[0];
  //       navigator.clipboard.writeText(item.valor);
  //     }
  //   }
  //   barcodeInput.current.focus();
  // };

  const changeTab = (index) => {
    if (parsedData.length > 0) {
      var item = parsedData[index];
      navigator.clipboard.writeText(item.valor);
    }
    setSelectedTab(index);
  };

  useEffect(() => {
    barcodeInput.current.focus();
  }, []);

  window.addEventListener("keydown", (e) => {
    var item = parsedData.find((x) => x.keyBind === e.key);
    if (item) {
      setSelectedTab(parsedData.indexOf(item));
      navigator.clipboard.writeText(item.valor);
      e.preventDefault();
    }
  });

  return (
    <div className="barcode-splitter">
      <div className="container">
        <div className="form-container">
          <Form>
            <FormRow>
              <InputContainer label={<LanguageProvider id="Barcode" />}>
                <InputText
                  autoFocus
                  ref={barcodeInput}
                  value={barcode}
                  onChange={(e) => setbarcode(e.target.value)}
                  onBlur={handleInputProcessing}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleInputProcessing();
                  }}
                />
                <input className="input-hidden"></input>
              </InputContainer>
            </FormRow>
            <FormRow>
              <div className="center-row-details">
                <ToggleButton
                  checked={viewMode}
                  onLabel="Lista"
                  offLabel="Abas"
                  onChange={(e) => setViewMode(e.value)}
                />
                <div className="center-items">
                  {parsedData.map((item, index) => {
                    return (
                      <>
                        {item.code == "01" && (
                          <>
                            <div>{item.valor}</div>
                          </>
                        )}
                        {item.code == "240" && (
                          <>
                            <div>{item.valor}</div>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
                <Button
                  label="Limpar"
                  size="medium"
                  type="button"
                  iconPos="right"
                  className="p-button"
                  onClick={() => setParsedData([])}
                />
              </div>
            </FormRow>
          </Form>
        </div>

        {/* TABS DO BARCODE */}
        {!viewMode && (
          <div className="tabs-container">
            <>
              <div className="tab-wrapper">
                {parsedData.map((item, index) => {
                  return (
                    <div className="">
                      <p className="keybind">{item.keyBind}</p>
                      <div
                        key={"tab-" + index}
                        className={`tab ${
                          selectedTab === index ? "selected" : ""
                        }`}
                        onClick={() => changeTab(index)}
                      >
                        <span>{item.nome}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="tab-content">
                {parsedData[selectedTab] && (
                  <Barcode
                    key={"tab-content-" + selectedTab}
                    value={parsedData[selectedTab].valor}
                  />
                )}
              </div>
            </>
          </div>
        )}

        {viewMode && (
          <div className="list-container">
            {parsedData.map((item, index) => {
              var angles = [0, 45, 0, -45];
              var rotation = angles[index % angles.length];

              return (
                <div
                  className={`barcode-wrapper ${
                    rotation !== 0 ? "rotate" : ""
                  }`}
                  style={{ transform: `rotate(${rotation}deg)` }}
                >
                  <span>{item.nome}</span>
                  <Barcode value={item.valor} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

const Barcode = ({ value }) => {
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, value, {
        format: "CODE128",
        lineColor: "#000",
        width: 2,
        height: 80,
        displayValue: true,
      });
    }
  }, [value]);

  return <svg ref={barcodeRef}></svg>;
};
