import { Navigate, Outlet } from "react-router-dom";
import React, { useLayoutEffect, useState } from "react";
import LoadingIndicator from "./dcp-loading-indicator";
import { propertyFrommStorage } from "~/services/storage/storage-access";

const DcpRoleProtectedRoute = ({ requiredRoles }) => {
  const [isMatchingRoles, setIsMatchingRoles] = useState(false);
  const [loading, setLoading] = useState(true);
  const userProductRoles = propertyFrommStorage(
    "authentication",
    "userClienteRoles"
  );

  const checkUserRoles = async () => {
    try {
      if (userProductRoles) {
        let productRoleRequired = requiredRoles[0].split(".")[0];
        const requiredRolesOnly = requiredRoles.map(
          (role) => role.split(".")[1]
        );

        if (productRoleRequired.includes("-")) {
          productRoleRequired = productRoleRequired.replace(/-/g, ".");
        }
        const rolesRequiredFormatted = userProductRoles.map(
          ({ roleName, applicationName }) => ({
            roleName,
            applicationName: applicationName.startsWith("product.")
              ? applicationName.substring(8)
              : applicationName,
          })
        );
        const rolesFromRequiredProductInUserRoles = rolesRequiredFormatted
          .filter((item) => item.applicationName === productRoleRequired)
          .map((item) => item.roleName);
        const rolesMatch = rolesFromRequiredProductInUserRoles.some((role) =>
          requiredRolesOnly.includes(role)
        );

        setIsMatchingRoles(rolesMatch);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setLoading(false), 1000);
    }
  };

  useLayoutEffect(() => {
    checkUserRoles();
  }, []);

  if (loading) return <LoadingIndicator />;

  if (isMatchingRoles) {
    return <Outlet />;
  } else {
    return <Navigate to={"/denied-access"} replace />;
  }
};

export default DcpRoleProtectedRoute;
