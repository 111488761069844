enum OrderMonitorStatus {
  createdOrder = 0,
  pendingPacking = 10,
  carrierDefinition = 20,
  carrierError = 21,
  pendingIntegrationCarrier = 30,
  errorCarrierIntegration = 31,
  pendingReport = 40,
  errorReport = 41,
  pendingPrint = 50,
  pendingShipping = 60,
  finished = 100,
}

export default OrderMonitorStatus;
