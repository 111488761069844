import "./item-list-dialog.scss";
import React, { useContext, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { GetMenuItems } from "./menu-items";
import { InputText } from "primereact/inputtext";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import Icon from "~/shared/components/icons";
import { ThemeContext } from "~/app";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";
import { listProductsFromUser } from "~/services/api";

export function ItemListDialog({ open, onClose, onAddItem, menuItems }) {
  const axiosService = useDcpAxiosService();
  const { currentLanguage } = useLanguageContext();
  const { theme, currentTheme } = useContext(ThemeContext);
  const [itemsList, setItemsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [reports, setReports] = useState([]);
  const [userProducts, setUserProducts] = useState(null);

  const handleScrollToCategory = (categoryId) => {
    const element = document.getElementById(categoryId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const fetchUserProducts = async () => {
    try {
      const { data, status } = await listProductsFromUser();
      if (status && Array.isArray(data)) {
        return data.map((product) => product.code);
      } else return [];
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function load() {
      setReports(await fetchReports(axiosService));
      setUserProducts(await fetchUserProducts());
    }
    load();
  }, [axiosService]);

  useEffect(() => {
    if (menuItems) {
      setItemsList(menuItems);
    } else {
      const items = GetMenuItems(reports);
      const filteredItemsByUserProducts = items?.filter(
        (item) =>
          userProducts !== null &&
          (item?.productCode.includes("platform") ||
            userProducts?.includes(item?.productCode))
      );
      const itemsWithMenuGenerated = GetMenuItems(filteredItemsByUserProducts);
      const itemsWithTranslatedLabel = itemsWithMenuGenerated.map((item) => {
        const translatedLabels = item?.items?.map((subItem) =>
          LanguageProviderWithoutContext({
            id: subItem.label?.props?.id,
            currentLanguage,
          })
        );
        return {
          ...item,
          items: item?.items?.map((subItem, index) => ({
            ...subItem,
            label:
              typeof translatedLabels[index] === "string"
                ? translatedLabels[index]
                : subItem?.label,
          })),
        };
      });
      const filteredItemsBySearchQuery = filterCategoriesItemsBySearchQuery(
        itemsWithTranslatedLabel
      );
      const filteredItemsListIsEmpty = filteredItemsBySearchQuery.every(
        (item) => item.items.length === 0
      );

      setItemsList(filteredItemsListIsEmpty ? [] : filteredItemsBySearchQuery);
    }
  }, [reports, menuItems, searchQuery]);

  const filterCategoriesItemsBySearchQuery = (items) => {
    return items.map((item) => {
      const filteredItems = item.items.filter((subItem) => {
        const label = typeof subItem.label === "string" ? subItem.label : "";
        return compareNormalized(
          removeAccents(label.toLowerCase()),
          removeAccents(searchQuery)
        );
      });
      return {
        ...item,
        items: filteredItems,
      };
    });
  };

  const compareNormalized = (target, fragment) => {
    if (typeof target !== "string" || typeof fragment !== "string") {
      return false;
    }
    const normalizedTarget = target.trim().toLowerCase();
    const normalizedFragment = fragment.trim().toLowerCase();

    return normalizedTarget.includes(normalizedFragment);
  };

  const removeAccents = (str) =>
    str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  return (
    <>
      <Dialog
        className="item-list-dialog"
        visible={open}
        onClose={onClose}
        onHide={onClose}
        blockScroll={true}
        closeOnEscape={true}
        dismissableMask={true}
        header={LanguageProviderWithoutContext({
          id: "dashboard.menu.title",
          currentLanguage,
        })}
        headerStyle={{
          color: currentTheme.textPrimary,
          borderBottom: `1px solid ${currentTheme.systemBackground}`,
          padding: "20px 20px 20px 30px",
        }}
        contentStyle={{
          paddingBottom: "0",
        }}
      >
        <div className="item-list-container">
          <div className="side-menu-categories">
            <span className="side-menu-title">
              <LanguageProvider id="dashboard.add.item" />
            </span>
            <div className="side-menu-wrapper">
              <ul className="category-list">
                {Array.isArray(itemsList) &&
                  Array.isArray(userProducts) &&
                  itemsList.length > 0 &&
                  itemsList
                    .filter((item) =>
                      userProducts.some(
                        (productCode) =>
                          productCode === item.productCode ||
                          item.productCode === "platform"
                      )
                    )
                    .map((item, index) => (
                      <li
                        className="category-opt"
                        key={index}
                        onClick={() => handleScrollToCategory(item.category)}
                      >
                        <span className="category-option-title">
                          {item.label}
                        </span>
                      </li>
                    ))}
              </ul>
            </div>
          </div>
          <div className="menu-categories-container">
            <div className="search-bar-container">
              <span className="p-input-icon-left">
                <div className="search-icon">
                  <Icon
                    color={currentTheme.textPrimary}
                    icon={"search-md"}
                    size={20}
                  />
                </div>
                <InputText
                  showClear={true}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={LanguageProviderWithoutContext({
                    id: "search.field.placeholder",
                    currentLanguage,
                  })}
                />
              </span>
            </div>
            {itemsList.length === 0 && (
              <div className="categories-empty-results">
                <h2>
                  <LanguageProvider id={"dashboard.add.item.empty.message"} />
                </h2>
              </div>
            )}

            {Array.isArray(reports) &&
              Array.isArray(userProducts) &&
              itemsList.map((category) => {
                if (
                  !userProducts.includes(category.productCode) &&
                  category.productCode !== "platform"
                ) {
                  return null;
                }
                if (category.items.length === 0) return null;
                let items = category.items;

                if (items.length === 0) return null;

                return (
                  <div className="categories-container" key={category.category}>
                    <p className="category-name" id={category.category}>
                      {category.label}
                    </p>
                    <div className="items-container">
                      {items.map((item, index) => {
                        const thumbnail = require(
                          `./item-thumbnails/${theme}/${category.productCode}/${item.type}.png`
                        );
                        return (
                          <div
                            className="item"
                            key={"item-" + index}
                            onClick={() => onAddItem(item.type)}
                          >
                            <div
                              className="image-container"
                              style={{
                                backgroundImage: `url(${thumbnail})`,
                              }}
                            ></div>
                            <div className="text-container">
                              <span className="item-title">{item.label}</span>
                              <p className="item-description">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Dialog>
    </>
  );
}

async function fetchReports(axiosService) {
  try {
    const { data, status } = await axiosService.get(
      settings.Urls.Rest.Report,
      "Platform"
    );

    if (status === 200 && Array.isArray(data.data)) return data.data;
    else return [];
  } catch (error) {
    console.error(error);
  }
}
