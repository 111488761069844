import { useContext } from "react";
import Icon from "~/shared/components/icons";
import { NavLink } from "react-router-dom";
import settings from "~/services/settings.json";
import { ThemeContext } from "~/app";

///Renders current project template with add actions
export const DcpNewItemPopupMenuTemplate = (item, options) => {
  return (
    <>
      <div
        className={`dcp-dashboard-nav-item-container ${options.className}`}
        role="menuitem"
        onKeyDown={(e) => {
          if (e.key === " ") e.stopPropagation();
        }}
      >
        <div className="nav-item-area" onClick={options.onClick}>
          {item.items && <span className={options.submenuIconClassName} />}
          <span className={`dcp-menu-item-label ${options.labelClassName}`}>
            {item.label}
          </span>
        </div>
      </div>
    </>
  );
};

///Renders all options inside a menu
export const DcpNavMenuItemTemplate = (item, options) => {
  const { currentTheme } = useContext(ThemeContext);
  return (
    <NavLink
      to={item.url}
      className={(isActive, isPending, isTransitioning) =>
        [
          options.className,
          isActive ? "active" : "",
          isPending ? "pending" : "",
          isTransitioning ? "transitioning" : "",
        ].join(" ")
      }
      role="menuitem"
      onClick={options.onClick}
      style={({ isActive }) => ({
        backgroundColor:
          isActive && (!item.items || item.items.length === 0)
            ? currentTheme.systemBackground
            : "transparent",
        borderRadius:
          isActive && (!item.items || item.items.length === 0) ? "8px" : "0",
      })}
    >
      {item.icon && (
        <Icon className={options.iconClassName} icon={item.icon} size={16} />
      )}

      {item.type === settings.Urls.Rest.Dashboard && (
        <div className="icon-container dashboards">
          <Icon icon={"pie-chart-05"} color={"#FF8B62"} />
        </div>
      )}

      {item.type === settings.DatalakeType.Database && (
        <div className="icon-container databases">
          <Icon icon={"database-03"} color={"#7893FF"} />
        </div>
      )}
      {item.type === settings.DatalakeType.Report && (
        <div className="icon-container reports">
          <Icon icon={"data"} color={"#ff8b62"} />
        </div>
      )}
      <span className={`dcp-menu-item-label ${options.labelClassName}`}>
        {item.label}
      </span>

      {item.items && item.iconPosition === "end" && (
        <Icon
          className={["chevron-right", options.iconClassName].join(" ")}
          icon={"chevron-right"}
          size={16}
        />
      )}
    </NavLink>
  );
};

export default DcpNavMenuItemTemplate;
