import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import OrderMonitorStatus from "~/shared/enums/B2b/OrderMonitorStatus";
import { DataTableFilter } from "~/shared/interfaces";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function ListB2bOrderProcess(
  filter: DataTableFilter
): Promise<any> {
  try {
    const { data, status } = await axiosService().post(
      settings.Urls.Rest.B2b + "/list-order-process",
      filter,
      "B2B"
    );
    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function GetOrderProcess(orderId: number): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.B2b + "/get-order-process",
      "B2B",
      { params: { id: orderId } }
    );

    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function GetCarriers(): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.B2b + "/carriers",
      "B2B"
    );

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}
