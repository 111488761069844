import React, { useContext, useState } from "react";
import { Dialog } from "primereact/dialog";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Button } from "primereact/button";
import Icon from "./icons";
import { ThemeContext } from "~/app";
import LanguageProvider from "./language-provider";
import "./scss/dcp-image-zoom.scss";


interface CustomZoomImageProps {
  src: string;
  alt?: string;
  className?: string;
  imageClassName?: string;
}

export function DcpCustomZoomImage({
  src,
  alt,
  className,
  imageClassName,
}: CustomZoomImageProps) {
  const currentTheme = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <img
        src={src}
        alt={alt}
        className={className}
        onClick={() => setVisible(true)}
        style={{
          cursor: "pointer",
          maxWidth: "100%",
          height: "100%",
        }}
      />

      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        header={LanguageProvider({
          id: "gen.preview.zoom.image.dialog.header",
        })}
        className="transform-weapper-content-dialog"
        style={{ maxWidth: "100%", maxHeight: "100%!important" }}
        maximizable
      >
        <TransformWrapper initialScale={1} minScale={1} maxScale={5}>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <div className="transform-weapper-content">
                <Button className="p-button-secondary" onClick={() => zoomIn()}>
                  <Icon
                    icon={"zoom-in"}
                    color={currentTheme.tableIconColor}
                    size={20}
                  />
                </Button>
                <Button
                  className="p-button-secondary"
                  onClick={() => zoomOut()}
                >
                  <Icon
                    icon={"zoom-out"}
                    color={currentTheme.tableIconColor}
                    size={20}
                  />
                </Button>
                <Button
                  className="p-button-secondary"
                  onClick={() => resetTransform()}
                >
                  <Icon
                    icon={"expand-06"}
                    color={currentTheme.tableIconColor}
                    size={20}
                  />
                </Button>
              </div>
              <TransformComponent>
                <img
                  src={src}
                  alt={alt}
                  className={imageClassName}
                  style={{
                    maxHeight: "100%",
                    height: "60vw"
                  }}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Dialog>
    </>
  );
}
