import "./excel-upload-dialog.scss";

import * as XLSX from "xlsx";

import { Dialog, DialogProps } from "primereact/dialog";
import {
  FileUpload,
  FileUploadHandlerEvent,
  FileUploadProps,
  ItemTemplateOptions,
} from "primereact/fileupload";
import React, { useRef, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import { Dropdown } from "primereact/dropdown";
import LanguageProvider from "~/shared/components/language-provider";
import { classNames } from "primereact/utils";
import uploadImg from "~/theme/media/assets/Upload.svg";

type ExcelUploadDialog = DialogProps & {
  requiredFields: { field: string; label: string | JSX.Element }[];
  onDataConfirm?: (data: any) => void;
};

const ExcelUploadDialog: React.FC<ExcelUploadDialog> = ({
  requiredFields,
  ...props
}) => {
  const fileUploadRef = useRef<FileUpload>(null);
  const fileInputRef = useRef(null);
  const [excelFileHeaders, setExcelFileHeaders] = useState<string[]>([]);
  const [fieldMappings, setFieldMappings] = useState<{
    [key: string]: string | null;
  }>({});
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [sheetRowCount, setSheetRowCount] = useState(null);
  const [mappedData, setMappedData] = useState([]);

  async function customUploadFunction(event: Partial<FileUploadHandlerEvent>) {
    const file = event.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target?.result;
      if (!data) return;
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      if (jsonData.length > 0) {
        const headers = jsonData[0] as string[];
        setExcelFileHeaders(headers);
        //-1 to ignore the header row
        setSheetRowCount(jsonData.length - 1);
        setFieldMappings(
          requiredFields.reduce(
            (acc, requiredField) => {
              acc[requiredField.field] = null;
              return acc;
            },
            {} as { [key: string]: string | null }
          )
        );
      }
    };
    reader.readAsArrayBuffer(file);
    setUploadedFile(file);
  }
  function clearData() {
    fileUploadRef?.current?.clear();
    setSheetRowCount(null);
    setFieldMappings({});
    setExcelFileHeaders([]);
  }

  const handleFieldMappingChange = (field: string, value: string | null) => {
    setFieldMappings((prev) => ({ ...prev, [field]: value }));
  };
  async function handleDataConfirmation() {
    if (!uploadedFile) {
      console.error("No file uploaded");
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target?.result;
      if (!data) return;
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json<string[]>(sheet, { header: 1 });

      if (jsonData.length < 2) {
        console.error("No data rows found in the sheet");
        return;
      }

      const headers = jsonData[0];
      const mappedData = jsonData.slice(1).map((row) => {
        let rowData: Record<string, any> = {};
        Object.keys(fieldMappings).forEach((key) => {
          const column = fieldMappings[key];
          if (column && headers.includes(column)) {
            const columnIndex = headers.indexOf(column);
            rowData[key] = row[columnIndex];
          }
        });
        return rowData;
      });
      if (props.onDataConfirm) {
        props.onDataConfirm(mappedData);
        props.onHide();
      } else {
        setMappedData(mappedData);
      }
    };
    reader.readAsArrayBuffer(uploadedFile);
  }
  async function handelEmptyTemplateClickFileSelection(event: any) {
    const file = event.target.files[0];
    if (file) {
      fileUploadRef.current.setFiles([file]);
      await customUploadFunction({ files: [file] });
    }
  }

  return (
    <Dialog
      {...props}
      className={classNames({
        "excel-upload-form": true,
        [props.className]: true,
      })}
      style={{ minWidth: 600 }}
      footer={() => (
        <div className="actions">
          {excelFileHeaders.length > 0 && (
            <Button appearance="secondary" type="button" onClick={clearData}>
              <LanguageProvider id={"gen.clear"} />
            </Button>
          )}
          <Button appearance="secondary" type="button" onClick={props.onHide}>
            <LanguageProvider id={"gen.cancel"} />
          </Button>
          {excelFileHeaders.length > 0 && (
            <Button
              className="p-button p-button-primary submit-btn"
              type="submit"
              onClick={handleDataConfirmation}
            >
              <LanguageProvider id={"gen.confirm"} />
            </Button>
          )}
        </div>
      )}
    >
      <FileUpload
        ref={fileUploadRef}
        accept=".xls,.xlsx,.csv"
        headerTemplate={() => <div />}
        emptyTemplate={(props: FileUploadProps) => {
          return (
            <div
              className="upload-file-wrapper"
              style={{ cursor: "pointer" }}
              onClick={() => {
                fileInputRef.current.click();
              }}
            >
              <img className="upload-file-img" src={uploadImg} alt="Upload" />
              <span className="upload-input-description">
                <LanguageProvider
                  id={"database.upload.file.title.drag.file.description"}
                />
              </span>
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handelEmptyTemplateClickFileSelection}
              />
            </div>
          );
        }}
        itemTemplate={(file: Object, options: ItemTemplateOptions) => (
          <div className="uploaded-file-extracted-headers-data">
            <div className="file-info">
              <span className="file-info-data">{options.fileNameElement}</span>
              <span className="file-info-data">
                <LanguageProvider
                  id={"excel.upload.form.identified.sheet.lenght"}
                />
                {": "}
                {sheetRowCount}
              </span>
            </div>
            {requiredFields.map((requiredField) => (
              <div
                key={requiredField.field}
                className="field-mapping-row"
                style={{ cursor: "pointer" }}
              >
                <span className="field-label">{requiredField.label}</span>
                <Dropdown
                  className="excel-form-field"
                  value={fieldMappings[requiredField.field] || null}
                  options={excelFileHeaders.map((header) => ({
                    label: header,
                    value: header,
                  }))}
                  onChange={(e) =>
                    handleFieldMappingChange(requiredField.field, e.value)
                  }
                  placeholder={"Select column"}
                />
              </div>
            ))}
          </div>
        )}
        auto
        customUpload
        uploadHandler={customUploadFunction}
      />
    </Dialog>
  );
};

export default ExcelUploadDialog;
