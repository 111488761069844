import "./B2bOrdersWithErrorStatus.scss";
import { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Icon from "~/shared/components/icons";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service"
import { Skeleton } from "primereact/skeleton";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import OrderMonitorStatus from "~/shared/enums/B2b/OrderMonitorStatus";
import ModalOrderMonitorDetails from "../../../../../../../../pages/b2b/orders-monitor/components/modal-order-monitor-details";
import { useNavigate } from "react-router-dom";
import { Button } from "~/shared/components/dcp-button";

export default function B2bOrdersWithErrorStatus(configs) {
  const dcpAxiosService = useDcpAxiosService();
  const useMockData = useContext(UseMockDataContext);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [orderId, setOrderId] = useState(0);
  const [orderDetailsDialogVisible, setOrderDetailsDialogVisible] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.DashboardItem + "/orders-with-error-status",
          "B2B"
        );

        if (status !== 200 || !data.data) return;

        setChartData(data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (useMockData) {
      //TODO: FAKEDATA FOR ORDERS WITH ERROR STATUS
      //setChartData(fakedata);
    } else {
      loadData();
    }
  }, [useMockData, configs]);

  function renderStatusColumn(status) {
    switch (status) {
      case OrderMonitorStatus.carrierError:
        return (
          <span className="b2b-orders-with-error-status-field">
            <LanguageProvider id="dashboard.item.b2b-orders-with-error-status.carrier.error" />
          </span>
        );
      case OrderMonitorStatus.errorCarrierIntegration:
        return (
          <span className="b2b-orders-with-error-status-field">
            <LanguageProvider id="dashboard.item.b2b-orders-with-error-status.carrier.integration.error" />
          </span>
        );
      case OrderMonitorStatus.errorReport:
        return (
          <span className="b2b-orders-with-error-status-field">
            <LanguageProvider id="dashboard.item.b2b-orders-with-error-status.report.error" />
          </span>
        );
      default:
        return <span>{status}</span>;
    }
  }

  function navigateToMonitor() {
    navigate("/b2b/b2b-orders-monitor");
  };

  const CloseOrderProcessModal = () => {
    setOrderDetailsDialogVisible(false);
  };

  return !loading ? (
    <>
      <div className="b2b-orders-with-error-status">
        <div className="header">
          <span className="header-message">
            <div className="total-items-wrapper">
              <span className="total-items-count">
                {chartData?.totalRecords}{" "}
                <LanguageProvider id="dashboard.database.registers" />
              </span>
            </div>
          </span>
        </div>
        <div className="body">
          <DataTable
            value={chartData?.ordersWithErrorStatus}
            removableSort
            rows={5}
            loading={loading}
          >

            <Column
              header={<LanguageProvider id={"gen.orderId"} />}
              field="orderId"
              filter
              filterPlaceholder={LanguageProvider({
                id: `${LanguageProvider({
                  id: "gen.filter.by",
                })} ${LanguageProvider({ id: "gen.orderId" })}`,
              })}
              sortable
            />

            <Column
              header={<LanguageProvider id={"gen.status"} />}
              field="statusDescription"
              filter
              sortable
              body={(rowData) => (
                <div className="order-status">
                  {renderStatusColumn(rowData.status)}
                </div>
              )}
            />

            <Column
              header={<LanguageProvider id={"gen.last.update"} />}
              field="lastUpdated"
              filter
              filterPlaceholder={LanguageProvider({
                id: `${LanguageProvider({
                  id: "gen.filter.by",
                })} ${LanguageProvider({ id: "gen.last.update" })}`,
              })}
              sortable
            />

            <Column
              headerStyle={{ width: "10%", minWidth: "100px" }}
              body={(rowData) => {
                return (
                  <div className="column-wrapper">
                    <div className="column-actions">
                      <div
                        className="icon-wrapper"
                        onClick={() => {
                          setOrderId(rowData?.id);
                          setOrderDetailsDialogVisible(true);
                        }
                        }
                      >
                        <Icon
                          icon="link-external-01"
                          size={20}
                          color="#667085"
                          className="icon-row"
                        />
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </DataTable>
          <div className="btn-area-contains">
            <Button
              text
              className="dcp-button  p-button p-component p-button-secondary btn-show-more"
              onClick={() => navigateToMonitor()}
              label={<LanguageProvider id="gen.show.more" />}
            />
          </div>
        </div>
        <ModalOrderMonitorDetails
          orderId={orderId}
          isOpen={orderDetailsDialogVisible}
          onClose={CloseOrderProcessModal}
        />
      </div>
    </>
  ) : (
    <div className="chart-load">
      <Skeleton height="33%"></Skeleton>
      <Skeleton height="100%"></Skeleton>
      <Skeleton height="66%"></Skeleton>
    </div>
  );
}