import "./theme/app.scss";
import "react-toastify/dist/ReactToastify.css";
import "./shared/scss/database-details.scss";
import { createContext, useEffect, useState } from "react";
import {
  getFromStorage,
  writeStorage,
} from "./services/storage/storage-access";
import { DcpAxiosProvider } from "./services/axios/dcp-axios-service";
import { LanguageProvider } from "~/context/LanguageContext";
import { MenuItemsContextProvider } from "./context/MenuItemsContext";
import NavProvider from "./nav-provider";
import { ToastContainer } from "react-toastify";
import { ToastContextProvider } from "./context/ToastContext";
import { getUserRoles } from "./services/api";

export const UserContext = createContext();
export const ThemeContext = createContext();

function App() {
  let loggedIn = false;
  let authentication = getFromStorage("authentication");

  if (authentication !== undefined) {
    loggedIn = true;
  }

  const [user, setUser] = useState({ loggedIn: loggedIn, userName: "" });
  const [userRoles, setUserRoles] = useState({
    platform: [],
    inventory: [],
    print: [],
    wmsCoreCeva: [],
  });

  function handleVerifyDashboardRolePermission(roles) {
    if (!roles) return null;
    clearUserRoles();

    const newRoles = {
      platform: [],
      "drone-inventory": [],
      print: [],
      "wms-core-ceva": [],
    };

    if (roles) {
      roles.forEach((role) => {
        if (role.applicationId === 1 && role.roleName === "administrator") {
          newRoles["platform"].push(role.roleName);
        }
        if (role.applicationId !== 1) {
          // Restrict access in products
          switch (role.applicationName) {
            case "product.inventory":
              newRoles["drone-inventory"].push(role.roleName);
              break;
            case "product.print":
              newRoles.print.push(role.roleName);
              break;
            case "product.wms.core.ceva":
              newRoles["wms-core-ceva"].push(role.roleName);
              break;
          }
          setUserRoles(newRoles);
        }
      });
    }
  }

  function clearUserRoles() {
    const newRoles = {
      platform: [],
      inventory: [],
      print: [],
      wmsCoreCeva: [],
    };
    setUserRoles(newRoles);
  }

  useEffect(() => {
    const userFromLocalStorage = getFromStorage("authentication");
    setUser(userFromLocalStorage);
    clearUserRoles();
    const refreshUserRoles = async () => {
      const userRolesRefresh = await getUserRoles(authentication.userName);
      return userRolesRefresh;
    };

    const handleRoleComparison = async () => {
      if (userFromLocalStorage) {
        const dataRefreshedRoles = await refreshUserRoles();
        const rolesFromDb = dataRefreshedRoles?.data.map(
          (role) => role.roleName
        );
        const rolesFromLocalStorage = userFromLocalStorage.userClienteRoles.map(
          (role) => role.roleName
        );
        const rolesAreDifferent = rolesFromDb.some(
          (role, index) => role !== rolesFromLocalStorage[index]
        );
        if (rolesAreDifferent) {
          handleVerifyDashboardRolePermission(dataRefreshedRoles.data);
          writeStorage("authentication", {
            ...userFromLocalStorage,
            userClienteRoles: dataRefreshedRoles.data,
          });
        } else {
          handleVerifyDashboardRolePermission(
            userFromLocalStorage.userClienteRoles
          );
        }
      }
    };
    handleRoleComparison();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userRoles,
        handleVerifyDashboardRolePermission,
        clearUserRoles,
      }}
    >
      <LanguageProvider>
        <DcpAxiosProvider>
          <ToastContainer icon={false} />
          <ToastContextProvider>
            <MenuItemsContextProvider>
              <NavProvider />
            </MenuItemsContextProvider>
          </ToastContextProvider>
        </DcpAxiosProvider>
      </LanguageProvider>
    </UserContext.Provider>
  );
}

export default App;
